<template>
  <div class="s">
    <div class="form" v-if="!waiting && !success">
      <div class="input">
        <h3>Email for recover passwrod</h3>
      </div>
      <div class="input">
        <input
          type="text"
          class="text-input"
          id="email_input"
          v-model="Email"
          placeholder="E-mail"
        />
        <br />
        <label class="email_label"></label>
      </div>
      <div class="input">
        <button class="send_btn" v-on:click="sendEmail()">Send a Link</button>
      </div>
    </div>
    <div v-if="waiting" class="box">
      <div class="loader"></div>
    </div>
    <div v-if="success">
      <h3>Check your Email to continue</h3>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import jquery from "jquery";
let $ = jquery;
export default {
  data() {
    return {
      Email: "",
      waiting: false,
      success: false,
    };
  },
  methods: {
    async sendEmail() {
      this.waiting = true;
      $("#email_input").click(function () {
        window.onkeydown = function () {
          $("#email_input").css("borderBottom", "3px solid #8884ff");
          $(".email_label").text("");
        };
      });
      await axios
        .post("/Auth/forgetPassword", { Email: this.Email })
        .then((res) => {
          if (res.data.err == "User Not found") {
            $("#email_input").css("borderBottom", "3px solid rgb(250,82,82)");
            $(".email_label").text("User doesn't found");
          }
          if (res.data.msg == "Email Sent") {
            this.waiting = false;
            this.success = true;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.s {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.input {
  padding: 1.2rem;
}
.text-input {
  width: 365px;
  height: 40px;
  padding: 10px;
  border: none;
  background: none;
  outline: none;
  border-bottom: 3px solid#8884ff;
}
.send_btn {
  color: #eeeeee;
  width: 365px;
  height: 40px;
  border-radius: 15px;
  background-color: #8884ff;
  &:hover {
    transition: all 300ms ease-in-out;
    background-color: #5f5cbb;
  }
}
.box {
  display: flex;
  justify-content: center;
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #8884ff;
  border-bottom: 16px solid #8884ff;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
