<template>
  <div>
    <div class="page">
      <h2 class="title">
        <router-link to="/Agent/AgentTickets" class="back_link"
          >Back to Agent</router-link
        >
        <br />
        {{ Ticket.Ticket_Subject }} / Type: {{ Ticket.Type }}
      </h2>
      <div class="Messages">
        <div class="msg">
          <div
            v-for="message in Messages"
            v-bind:key="message.ID"
            :id="`message-${message.ID}`"
            class="message"
          >
            <div class="name" :id="`name-${message.ID}`">
              {{ Ticket.Name }}
            </div>
            <div class="content" :id="`content-${message.ID}`">
              {{ message.Message }}
              <div class="images" v-if="message.images.length != 0">
                <div
                  class="image"
                  v-for="image in message.images"
                  v-bind:key="image.ID"
                  v-on:click="openPhotoViewer(message.images)"
                >
                  <img :src="`/uploads/${image.Image_Name}`" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form">
        <div class="choose_file">
          <label for="choose_file">
            <input
              type="file"
              name="CategoryImage"
              ref="file"
              @change="onFileSelected"
              id="choose_file"
              multiple
            />
            <span><i class="fa-solid fa-paperclip"></i></span>
          </label>
        </div>
        <input
          type="text"
          class="message_input"
          id="MessageInput"
          @keyup.enter="sendMessage()"
          v-model="Message"
          placeholder="Enter a Message"
        />
        <button
          v-on:click="sendMessage()"
          class="send_msg_btn"
          id="sendMessage"
        >
          <i class="fa-regular fa-paper-plane"></i>
        </button>
        <button v-on:click="showCloseConfirmationPopup()" class="send_msg_btn">
          <i class="fa-solid fa-lock"></i>
        </button>
      </div>
      <div class="file_list">
        <div class="file" v-for="file in Files" v-bind:key="file.size">
          <div class="icon"><i class="fa-regular fa-image"></i></div>
          <div class="name">{{ file.name }}</div>
          <div class="cancel">
            <button class="close_btn" v-on:click="removeFile(file.name)">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="dispage" v-if="photoViewer">
        <div class="photo_viewer">
          <div class="close_button">
            <button v-on:click="closePhotoViewer()">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div class="ma">
            <button class="prev_btn" v-on:click="prevImg()">
              <i class="fa-solid fa-chevron-left"></i>
            </button>
            <div class="photo">
              <div class="img">
                <img :src="`/uploads/${Images[currentIndex].Image_Name}`" />
              </div>
            </div>
            <button class="next_btn" v-on:click="nextImg()">
              <i class="fa-solid fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
      <!--===================== close confirmation Section =====================-->
      <div v-if="closeConfirmation">
        <div class="close_confirmation">
          <div class="dispage">
            <div class="form1">
              <div class="close">
                <button v-on:click="closeCloseConfirmationPopup()">
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </div>
              <div class="input">
                <h4>Are you Sure you want to Close Ticket ?</h4>
              </div>
              <div class="input">
                <button class="yes_btn" v-on:click="acceptClose()">
                  <i class="fa-solid fa-check"></i> Yes
                </button>
                <button class="no_btn" v-on:click="rejectClose()">
                  <i class="fa-solid fa-xmark"></i> No
                </button>
              </div>
              <div class="input">
                <label class="success_msg_1"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import jquery from "jquery";
let $ = jquery;
export default {
  data() {
    return {
      Messages: [],
      AID: "",
      Ticket: {},
      photoViewer: false,
      Images: [],
      currentIndex: 0,
      Files: [],
      Message: "",
      closeConfirmation: false,
    };
  },
  async mounted() {
    this.$store.dispatch("isAgentLogIN");
    $(".message_input").click(function () {
      window.onkeydown = function () {
        $(".message_input").css("border", "1.2px solid #8884ff");
      };
    });
    window.addEventListener("click", function () {
      $(".message_input").css("border", "1.2px solid grey");
    });
    this.loadMessages();
  },
  methods: {
    async loadMessages() {
      await axios
        .get(`/Agent/getMessages/${this.$route.params.id}`, {
          headers: { token: localStorage.getItem("token") },
        })
        .then(async (res) => {
          this.Messages = await res.data.messages;
          this.AID = await res.data.AdminID;
          this.Ticket = await res.data.tikcetInfo;
          for (let i = 0; i < this.Messages.length; i++) {
            if (this.Messages[i].UserID != this.AID) {
              $(`#message-${this.Messages[i].ID}`).addClass("left");
              $(`#content-${this.Messages[i].ID}`).addClass("user");
            } else {
              $(`#name-${this.Messages[i].ID}`).hide();
              $(`#message-${this.Messages[i].ID}`).addClass("right");
              $(`#content-${this.Messages[i].ID}`).removeClass("user");
            }
          }
        });
    },
    onFileSelected() {
      this.Files = this.$refs.file.files;
    },
    openPhotoViewer(Images) {
      this.photoViewer = true;
      this.Images = Images;
      this.currentIndex = 0;
    },
    closePhotoViewer() {
      this.photoViewer = false;
    },
    prevImg() {
      if (this.currentIndex != 0) {
        this.currentIndex -= 1;
      }
    },
    nextImg() {
      if (this.currentIndex < 2) {
        this.currentIndex += 1;
      }
    },
    async sendMessage() {
      $(".message_input").click(function () {
        window.onkeydown = function () {
          $(".message_input").css("border", "1.2px solid rgb(85, 85, 85)");
        };
      });
      if (this.Message == "") {
        $(".message_input").css("border", "1.2px solid rgb(250,82,82)");
        return;
      }

      const fd = new FormData();
      fd.append("Message", this.Message);
      fd.append("TicketID", this.$route.params.id);
      for (let i = 0; i < this.Files.length; i++) {
        fd.append("Images", this.Files[i]);
      }
      await axios
        .post("/Agent/sendMessage", fd, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.msg == "Message Sent") {
            this.loadMessages();
            this.Files = [];
            this.Message = "";
          }
        });
    },
    showCloseConfirmationPopup() {
      this.closeConfirmation = true;
      setTimeout(() => {
        $(".close_confirmation").fadeIn(200);
      }, 0);
    },
    closeCloseConfirmationPopup() {
      $(".close_confirmation").fadeOut(200);
      setTimeout(() => {
        this.closeConfirmation = false;
      }, 1000);
    },
    async CloseTicket() {
      await axios
        .patch(
          `/Agent/CloseTicket`,
          { TicketID: this.$route.params.id },
          { headers: { token: localStorage.getItem("token") } }
        )
        .then((res) => {
          if (res.data.msg == "Ticket Closed") {
            this.$router.push({ name: "AgentTickets" });
          }
        });
    },
    acceptClose() {
      this.CloseTicket();
      this.closeCloseConfirmationPopup();
    },
    rejectClose() {
      this.closeCloseConfirmationPopup();
    },
    removeFile(name) {
      let arr = [];
      for (let i = 0; i < this.Files.length; i++) {
        if (this.Files[i].name != name) {
          arr.push(this.Files[i]);
        }
      }
      this.Files = arr;
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  margin: 4rem;
}
.title {
  text-align: left;
}
.back_link {
  text-align: left;
  color: #1f2833;
  transition: all 300ms ease-in-out;
  &:hover {
    color: #8884ff;
  }
}
.Messages {
  padding: 2rem;
  height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #1f2833;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.message {
  margin: 1rem;
  width: 400px;
  text-align: justify;
}
.left {
  position: relative;
  left: 0%;
}
.right {
  position: relative;
  right: -70%;
}
.name {
  color: #eeeeee;
}
.content {
  background-color: #8884ff;
  color: #eeeeee;
  padding: 10px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow-wrap: break-word;
}
.images {
  display: flex;
  justify-content: center;
}
.image {
  width: 60px;
  height: 60px;
  margin: 1rem;
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}
.image:hover {
  cursor: pointer;
}
.user {
  color: #1f2833;
  background-color: #e0e0e0;
}
.form {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #1f2833;
  height: 105px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.file_list {
  width: 400px;
  text-align: left;
}
.file {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.icon {
  font-size: 22px;
  color: #8884ff;
}
.name {
  color: #1f2833;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.close_btn {
  font-size: 22px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    color: #8884ff;
  }
}
.remove_file {
  background: none;
  outline: none;
  outline: none;
  transition: all 300ms ease-in-out;
  color: #1f2833;
  margin-left: 1rem;
  font-size: 22px;
  &:hover {
    cursor: pointer;
    color: #8884ff;
  }
}
.message_input {
  width: 950px;
  height: 50px;
  background: none;
  border: none;
  outline: none;
  color: #eeeeee;
  font-size: 16px;
  padding: 10px;
  border: 1.2px solid rgb(85, 85, 85);
  border-radius: 10px;
}
::placeholder {
  font-size: 16px;
}
.choose_file label {
  display: block;
  border: 2px dashed #8884ff;
  padding: 15px;
  margin: 10px;
  text-align: center;
  cursor: pointer;
}
.choose_file #choose_file {
  outline: none;
  opacity: 0;
  width: 0;
}
.choose_file span {
  font-size: 14px;
  color: #8884ff;
}
.choose_file label:hover span {
  text-decoration: underline;
}
.send_msg_btn {
  color: #eeeeee;
  width: 50px;
  height: 50px;
  font-size: 18px;
  background-color: #8884ff;
  border-radius: 15px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #5f5bbc;
  }
}
@media screen and (max-width: 1500px) {
  .message_input {
    width: 750px;
  }
}
@media screen and (max-width: 1387px) {
  .message_input {
    width: 550px;
  }
}
@media screen and (max-width: 1190px) {
  .message_input {
    width: 350px;
  }
  .right {
    width: 200px;
  }
}
@media screen and (max-width: 975px) {
  .message_input {
    width: 260px;
  }
}
@media screen and (max-width: 490px) {
  .page {
    margin: 1rem;
  }
  .message {
    width: 200px;
  }
}
@media screen and (max-width: 420px) {
  .message_input {
    width: 180px;
  }
  .right {
    position: relative;
    right: 0%;
  }
}
/******************** Photo Viewer Section ********************/
.photo_viewer {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  z-index: 3500;
  background-color: #575c6370;
  height: 100vh;
}
.dispage {
  display: flex;
  flex-direction: column;
  z-index: 3000;
  // background-color: red;
}
.close_button {
  width: 100%;
  text-align: right;
  padding: 2rem;
  button {
    font-size: 32px;
    color: #1f2833;
    transition: all 300ms ease-in-out;
    &:hover {
      cursor: pointer;
      color: #8884ff;
    }
  }
}
.ma {
  display: flex;
  height: 80vh;
  padding: 2rem;
  justify-content: space-between;
  align-items: center;
}
.photo {
  display: flex;
  .img {
    img {
      width: 100%;
      height: 100%;
      max-height: 70vh;
    }
  }
}
.prev_btn,
.next_btn {
  font-size: 32px;
  color: #1f2833;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    color: #8884ff;
  }
}
/******************** Close Confirmation popup Section ********************/
.close_confirmation {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: #0c0f1287;
  z-index: 2500;
  display: flex;
  flex-direction: column;
  display: none;
}
.dispage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 3000;
  background-color: #0c0f1287;
}
.form1 {
  width: 300px;
  height: 250px;
  background-color: #1f2833;
  border-radius: 15px;
}
.close {
  text-align: right;
  margin: 1rem;
  button {
    color: #eeeeee;
    transition: all 300ms ease-in-out;
    i {
      font-size: 26px;
    }
    &:hover {
      cursor: pointer;
      color: #8884ff;
    }
  }
}
.yes_btn {
  width: 70px;
  height: 40px;
  font-size: 17px;
  border-radius: 10px;
  color: #eeeeee;
  transition: all 300ms ease-in-out;
  i {
    color: rgb(62, 254, 62);
  }
  &:hover {
    color: #8884ff;
    cursor: pointer;
  }
}
.no_btn {
  width: 70px;
  height: 40px;
  font-size: 17px;
  border-radius: 10px;
  color: #eeeeee;
  transition: all 300ms ease-in-out;
  i {
    color: rgb(250, 82, 82);
  }
  &:hover {
    color: #8884ff;
    cursor: pointer;
  }
}
.input {
  display: flex;
  justify-content: center;
  padding: 1.2rem;
  h4 {
    color: #eeeeee;
  }
}
</style>
