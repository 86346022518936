import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("token") || null,
    isAdmin: localStorage.getItem("#5s12") || null,
    isAgent: localStorage.getItem("#5s13") || null,
  },
  getters: {
    isLoggedIn(state) {
      return state.token !== null;
    },
    isAgentLoggedIn(state) {
      return state.isAgent !== null;
    },
    isAdminLoggedIn(state) {
      return state.isAdmin !== null;
    },
  },
  mutations: {
    login(state, token) {
      state.token = token;
    },
    agent(state, isAgent) {
      state.isAgent = isAgent;
    },
    admin(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
  },
  actions: {
    isLogIN() {
      return new Promise((resolve, reject) => {
        axios
          .get("/Auth/isLoggedIn", {
            headers: { token: localStorage.getItem("token") },
          })
          .then((res) => {
            if (res.data.msg == "Token expired") {
              localStorage.clear();
              location.replace("/");
              resolve(res);
            } else if (res.data.msg == "LoggedIn") {
              resolve(res);
            } else {
              localStorage.clear();
              location.replace("/");
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    isAgentLogIN() {
      return new Promise((resolve, reject) => {
        axios
          .get("/Auth/isAgentLoggedIn", {
            headers: { token: localStorage.getItem("token") },
          })
          .then((res) => {
            if (res.data.msg == "Token expired") {
              localStorage.clear();
              location.replace("/");
              resolve(res);
            } else if (res.data.msg == "LoggedIn") {
              resolve(res);
            } else {
              localStorage.clear();
              location.replace("/");
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    isAdminLogIN() {
      return new Promise((resolve, reject) => {
        axios
          .get("/Auth/isAdminLoggedIn", {
            headers: { token: localStorage.getItem("token") },
          })
          .then((res) => {
            if (res.data.msg == "Token expired") {
              localStorage.clear();
              location.replace("/");
              resolve(res);
            } else if (res.data.msg == "LoggedIn") {
              resolve(res);
            } else {
              localStorage.clear();
              location.replace("/");
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    Login(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/Auth/Login", {
            Email: data.email,
            Password: data.password,
          })
          .then((res) => {
            if (res.data.msg == "Admin LoggedIn") {
              localStorage.setItem("#5s12", true);
              context.commit("admin", true);
              localStorage.setItem("token", res.data.token);
              context.commit("login", res.data.token);
              resolve(res);
            }
            if (res.data.msg == "Agent LoggedIn") {
              localStorage.setItem("#5s13", true);
              context.commit("agent", true);
              localStorage.setItem("token", res.data.token);
              context.commit("login", res.data.token);
              resolve(res);
            }
            if (res.data.msg == "User LoggedIn") {
              localStorage.setItem("token", res.data.token);
              context.commit("login", res.data.token);
              resolve(res);
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    Logout() {
      localStorage.clear();
      location.replace("/");
    },
  },
  modules: {},
});
