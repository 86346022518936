<template>
  <div>
    <header>
      <div class="Icon">
        <div class="logo">
          <img :src="Logo" />
        </div>
        <div class="title">
          <span>{{ Name }}</span>
        </div>
      </div>
      <div class="Links">
        <ul>
          <li v-if="isLoggedIn && !isAgentLoggedIn && !isAdminLoggedIn">
            <router-link to="/profile">Profile</router-link>
          </li>
          <li v-if="isLoggedIn && isAgentLoggedIn">
            <router-link to="/Agent/AgentTickets">Agent</router-link>
          </li>
          <li v-if="isLoggedIn && isAdminLoggedIn">
            <router-link to="/Admin/Tickets">Admin</router-link>
          </li>
          <li v-if="!isLoggedIn">
            <router-link to="/Login">Login</router-link>
          </li>
          <li v-if="!isLoggedIn">
            <router-link to="/Signup" class="sign_up">Sign up</router-link>
          </li>
          <li v-if="isLoggedIn && isAgentLoggedIn && !isAdminLoggedIn">
            <router-link to="/Ticket">Create Ticket</router-link>
          </li>
          <li
            v-if="isLoggedIn || isAgentLoggedIn || isAdminLoggedIn"
            v-on:click="Logout()"
          >
            <div class="Logout_link">Logout</div>
          </li>
        </ul>
        <button class="show_nav" v-on:click="openNav()">
          <div class="line1"></div>
          <div class="line2"></div>
          <div class="line3"></div>
        </button>
      </div>
    </header>
    <div class="mobile-nav">
      <ul>
        <li v-if="isLoggedIn && !isAgentLoggedIn && !isAdminLoggedIn">
          <router-link to="/profile">Profile</router-link>
        </li>
        <li v-if="isLoggedIn && isAgentLoggedIn">
          <router-link to="/Agent/AgentTickets">Agent</router-link>
        </li>
        <li v-if="isLoggedIn && isAdminLoggedIn">
          <router-link to="/Admin/Tickets">Admin</router-link>
        </li>
        <li v-if="!isLoggedIn">
          <router-link to="/Login">Login</router-link>
        </li>
        <li v-if="!isLoggedIn">
          <router-link to="/Signup" class="sign_up">Sign up</router-link>
        </li>
        <li v-if="isLoggedIn && !isAgentLoggedIn && !isAdminLoggedIn">
          <router-link to="/Ticket">Create Ticket</router-link>
        </li>
        <li
          v-if="isLoggedIn || isAgentLoggedIn || isAdminLoggedIn"
          v-on:click="Logout()"
        >
          <div class="Logout_link">Logout</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      Logo: "",
      Name: "",
      isLoggedIn: false,
      isAgentLoggedIn: false,
      isAdminLoggedIn: false,
    };
  },
  async mounted() {
    await axios.get("/Admin/getLogo").then(async (res) => {
      this.Logo = `/uploads/${await res.data.Image}`;
      this.Name = await res.data.websiteName;
    });
    this.isLoggedIn = await this.$store.getters.isLoggedIn;
    this.isAgentLoggedIn = await this.$store.getters.isAgentLoggedIn;
    this.isAdminLoggedIn = await this.$store.getters.isAdminLoggedIn;
  },
  methods: {
    openNav() {
      let nav = document.getElementsByClassName("mobile-nav")[0];
      if (nav.classList[1] != "show") {
        nav.classList.add("show");
      } else {
        nav.classList.remove("show");
      }
    },
    Logout() {
      this.$store.dispatch("Logout");
    },
  },
};
</script>

<style lang="scss" scoped>
/******************* Header *******************/
header {
  width: 100%;
  height: 80px;
  background: #1f2833;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.Icon {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.logo {
  img {
    width: 40px;
    height: 40px;
  }
}
.title {
  color: #eeeeee;
  font-weight: 900;
  font-size: 18px;
}
.Links ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  li a {
    font-weight: 800;
    margin: 2rem;
    color: #eeeeee;
    transition: all 0.35s ease-out;
    &:hover {
      color: #8884ff;
    }
  }
}
.Logout_link {
  font-weight: 800;
  color: #eeeeee;
  transition: all 0.35s ease-out;
  &:hover {
    cursor: pointer;
    color: #8884ff;
  }
}
.show_nav {
  background: none;
  border: none;
  outline: none;
  direction: rtl;
  display: none;
  width: 40px;
  height: 40px;
  div {
    margin: 9px;
    text-align: right;
  }
}
.line1,
.line3 {
  width: 30px;
  height: 2px;
  background-color: #eeeeee;
  transition: all 200ms ease-in-out;
}
.line2 {
  width: 25px;
  height: 2px;
  background-color: #eeeeee;
  transition: all 200ms ease-in-out;
}
.show_nav:hover {
  cursor: pointer;
}
.mobile-nav {
  ul {
    display: none;
  }
  ul li {
    opacity: 0;
    padding: 10px;
  }
  li a {
    font-weight: 800;
    color: #eeeeee;
    transition: all 0.35s ease-out;
    &:hover {
      color: #8884ff;
    }
  }
  ul li a {
    display: none;
  }
}
.show {
  background-color: #1f2833;
  width: 100%;
  ul {
    display: block;
  }
  ul li {
    opacity: 1;
  }
  ul li a {
    display: block;
  }
}
@media screen and (max-width: 1200px) {
  .Links ul {
    display: none;
  }
  .show_nav {
    display: block;
  }
}
@media screen and (min-width: 1200px) {
  .show {
    display: none;
  }
}
</style>
