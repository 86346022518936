<template>
  <div>
    <!--===================== Navbar Section =====================-->
    <NavBar />
    <!--===================== Ticket Section =====================-->
    <div class="a">
      <div class="ticket_request">
        <div v-if="!success" class="box">
          <div>
            <h2>Create Ticket Request</h2>
            <form method="post" class="form" @submit.prevent="addTicket()">
              <div class="input">
                <input
                  type="text"
                  placeholder="Ticket Subject"
                  v-model="Subject"
                  class="text_input"
                  id="subject_input"
                />
                <br />
                <label for="subject_input" id="subject_input_label"></label>
              </div>
              <div class="input">
                <div class="dropdown">
                  <div class="dropbtn">Select Type: {{ selected_type }}</div>
                  <div class="dropdown-content">
                    <div
                      v-for="type in Types"
                      v-bind:key="type.ID"
                      v-on:click="getType(type.Name)"
                    >
                      {{ type.Name }}
                    </div>
                  </div>
                </div>
                <br />
                <label for="type_input" id="type_input_label"></label>
              </div>
              <div class="input">
                <span class="label_title">Message</span>
                <br />
                <textarea
                  id="message_input"
                  class="msg_input"
                  name="Message"
                  v-model="Message"
                >
              Message</textarea
                >
                <br />
                <label for="message_input" id="message_input_label"></label>
              </div>
              <button class="submit-btn">Create Ticket</button>
            </form>
          </div>
        </div>
        <div class="files" v-if="!success">
          <div class="files_wrapper">
            <h2>Add Attachments here</h2>
            <span class="file_err"></span>
            <div class="file_input">
              <div class="choose_file">
                <label for="choose_file">
                  <input
                    type="file"
                    name="CategoryImage"
                    ref="file"
                    @change="onFileSelected"
                    id="choose_file"
                    multiple
                  />
                  <span>Choose Files</span>
                </label>
              </div>
            </div>
            <div class="files_names">
              <div class="file" v-for="file in Files" v-bind:key="file.size">
                <div class="icon"><i class="fa-regular fa-image"></i></div>
                <div class="name">{{ file.name }}</div>
                <div class="cancel">
                  <button class="close_btn" v-on:click="removeFile(file.name)">
                    <i class="fa-solid fa-xmark"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="success">
          <div class="success_msg">
            <h3>Thank You!</h3>
            <p>We will respond to you shortly.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import axios from "axios";
import jquery from "jquery";
let $ = jquery;

export default {
  data() {
    return {
      Types: [],
      selected_type: "",
      Subject: "",
      Message: "",
      Files: [],
      success: false,
      isAgentLoggedIn: false,
    };
  },
  async mounted() {
    // this.$store.dispatch("isLogIN");
    await axios.get("/User/getTypesTicket").then((res) => {
      this.Types = res.data;
    });
    this.isAgentLoggedIn = await this.$store.getters.isAgentLoggedIn;
  },
  components: {
    NavBar,
  },
  methods: {
    onFileSelected() {
      this.Files = this.$refs.file.files;
    },
    getType(Name) {
      this.selected_type = Name;
      $("#type_input_label").text("");
    },
    async addTicket() {
      $("#subject_input").click(function () {
        window.onkeydown = function () {
          $("#subject_input").css("borderBottom", "3px solid #8884ff");
          $("#subject_input_label").text("");
        };
      });
      $("#message_input").click(function () {
        window.onkeydown = function () {
          $("#message_input").css("border", "3px solid #8884ff");
        };
      });
      if (
        this.selected_type == "" &&
        this.Subject == "" &&
        this.Message == ""
      ) {
        $("#type_input_label").text("Please Choose Type");

        $("#subject_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $("#subject_input_label").text("Please Enter Subject");

        $("#message_input").css("border", "3px solid rgb(250,82,82)");
        return;
      }
      if (this.selected_type == "") {
        $("#type_input_label").text("Please Choose Type");
        return;
      }
      if (this.Subject == "") {
        $("#subject_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $("#subject_input_label").text("Please Enter Subject");
        return;
      }
      if (this.Message == "") {
        $("#message_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $("#message_input_label").text("Please Enter Message");
        return;
      }
      if (this.Files.length > 3) {
        $(".file_err").text("Maximum 3 photos");
        return;
      }
      const fd = new FormData();
      fd.append("Subject", this.Subject);
      for (let i = 0; i < this.Files.length; i++) {
        fd.append("Images", this.Files[i]);
      }
      fd.append("Status", "New");
      fd.append("Type", this.selected_type);
      fd.append("Message", this.Message);
      fd.append("isAgent", this.$store.getters.isAgentLoggedIn);
      await axios
        .post("/User/addTicket", fd, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.msg == "Ticket Created" && !this.isAgentLoggedIn) {
            this.success = true;
            setTimeout(() => {
              this.$router.push("/profile");
            }, 4000);
          } else {
            this.success = true;
            setTimeout(() => {
              this.$router.push("/Agent/AgentTickets");
            }, 4000);
          }
        });
    },
    removeFile(name) {
      let arr = [];
      for (let i = 0; i < this.Files.length; i++) {
        if (this.Files[i].name != name) {
          arr.push(this.Files[i]);
        }
      }
      this.Files = arr;
    },
  },
};
</script>

<style lang="scss" scoped>
.msg_input {
  width: 365px;
  height: 100px;
  max-height: 100px;
  min-width: 100px;
  max-width: 500px;
}

/*********************** Left section ***********************/
.ticket_request {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 2rem;
  width: 100%;
}
.form {
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.box {
  display: flex;
}
.input {
  padding: 2rem;
  text-align: left;
  label {
    color: rgb(250, 82, 82);
  }
}
.label_title {
  color: #1f2833;
}
.text_input {
  width: 360px;
  height: 30px;
  background: none;
  outline: none;
  border: none;
  border-bottom: 3px solid #8884ff;
  padding: 0.2rem;
  font-size: 16px;
}

.dropbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #8884ff;
  color: white;
  width: 360px;
  height: 55px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  border-radius: 10px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 360px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
}

.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 10px;
}

.dropdown-content div:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #5f5cbd;
}
.submit-btn {
  width: 360px;
  height: 45px;
  background: none;
  outline: none;
  border: none;
  background-color: #8884ff;
  color: #eeeeee;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
  font-size: 17px;
  font-weight: 600;
  &:hover {
    cursor: pointer;
    background-color: #5f5cbd;
  }
}
@media screen and (max-width: 800px) {
  .a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ticket_request {
    display: block;
  }
  .box {
    display: flex;
    justify-content: center;
  }
  .files {
    justify-content: center;
  }
}

@media screen and (max-width: 420px) {
  .form {
    width: 300px;
  }
  .msg_input,
  .submit-btn,
  .text_input,
  .dropbtn,
  .dropdown-content {
    width: 200px;
  }
}

/*********************** right section ***********************/
.files {
  display: flex;
  justify-content: center;
}
.files_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.file_input {
  width: 365px;
}
.choose_file label {
  display: block;
  border: 2px dashed #8884ff;
  padding: 15px;
  margin: 10px;
  text-align: center;
  cursor: pointer;
}
.choose_file #choose_file {
  outline: none;
  opacity: 0;
  width: 0;
}
.choose_file span {
  font-size: 14px;
  color: #8884ff;
}
.choose_file label:hover span {
  text-decoration: underline;
}
.file_err {
  color: rgb(250, 82, 82);
}
.files_names {
  width: 365px;
  height: 470px;
}
.file {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.icon {
  font-size: 22px;
  color: #8884ff;
}
.name {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.close_btn {
  font-size: 22px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    color: #8884ff;
  }
}
@media screen and (max-width: 420px) {
  .file_input {
    width: 200px;
  }
  .files_names {
    width: 200px;
  }
}
/*********************** Thank You section ***********************/
.success_msg {
  width: 400px;
  text-align: center;
  p {
    width: 400px;
    text-align: center;
  }
}
</style>
