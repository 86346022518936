<template>
  <div>
    <NavBar />
    <div class="article">
      <div class="title">
        <h2>{{ Article.Title }}</h2>
      </div>
      <div class="content" v-html="Article.ArticleContent"></div>
      <div class="date">{{ Article.Date.split("T")[0] }}</div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import axios from "axios";

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      Article: {},
    };
  },
  async mounted() {
    await axios
      .get(`/User/getArticle/${this.$route.params.id}`)
      .then((res) => {
        this.Article = res.data;
      });
  },
};
</script>

<style lang="scss" scoped>
.article {
  text-align: justify;
  padding: 4rem;
}
.title {
  display: flex;
  align-items: center;
  padding: 1rem;
}
.content {
  padding: 1rem;
  margin: 2.5rem;
}
.date {
  padding: 1rem;
  margin: 2.5rem;
}
</style>
