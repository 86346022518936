<template>
  <div>
    <div v-if="!success2">
      <div class="form" v-if="success">
        <div class="input">
          <h3>Set new Passwrod</h3>
        </div>
        <div class="input">
          <input
            type="password"
            class="text-input"
            id="password_input"
            v-model="newPassword"
            placeholder="Password"
          />
          <br />
          <label class="password_label"></label>
        </div>
        <div class="input">
          <input
            type="password"
            class="text-input"
            id="confirm_password_input"
            v-model="confirmPassword"
            placeholder="Confirm Password"
          />
          <br />
          <label class="confirm_password_label"></label>
        </div>
        <div class="input">
          <button class="send_btn" v-on:click="updatePassword()">Update</button>
        </div>
      </div>
    </div>
    <div v-if="success2">
      <h3>Password Updated</h3>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import jquery from "jquery";
let $ = jquery;
export default {
  data() {
    return {
      newPassword: "",
      confirmPassword: "",
      success: false,
      success2: false,
    };
  },
  async mounted() {
    console.log(this.$route.params.id);
    await axios.get(`/Auth/confirm/${this.$route.params.id}`).then((res) => {
      console.log(res);
      if (res.data.msg == "User is Here") {
        this.success = true;
      } else {
        location.replace("/");
      }
    });
  },
  methods: {
    async updatePassword() {
      $("#password_input").click(function () {
        window.onkeydown = function () {
          $("#password_input").css("borderBottom", "3px solid #8884ff");
          $(".password_label").text("");
        };
      });
      if (this.newPassword == "" && this.confirmPassword == "") {
        $("#password_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".password_label").text("Please Enter this field");
        $("#confirm_password_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".confirm_password_label").text("Please Enter this field");
        return;
      }
      if (this.newPassword == "") {
        $("#password_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".password_label").text("Please Enter this field");
        return;
      }
      if (this.confirmPassword == "") {
        $("#confirm_password_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".confirm_password_label").text("Please Enter this field");
        return;
      }
      if (this.newPassword != this.confirmPassword) {
        $("#password_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".password_label").text("Password don't match");
        return;
      }
      await axios
        .post(
          "/Auth/setNewpassword",
          { newPassword: this.newPassword },
          { headers: { token: this.$route.params.id } }
        )
        .then((res) => {
          if (res.data.msg == "Password Updated") {
            this.success2 = true;
            setTimeout(() => {
              this.$router.push("/Login");
            }, 1500);
          }
          if (res.data.msg == "User Not found") {
            setTimeout(() => {
              location.replace("/");
            }, 1500);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  padding: 1.2rem;
}
.text-input {
  width: 365px;
  height: 40px;
  padding: 10px;
  border: none;
  background: none;
  outline: none;
  border-bottom: 3px solid#8884ff;
}
.send_btn {
  color: #eeeeee;
  width: 365px;
  height: 40px;
  border-radius: 15px;
  background-color: #8884ff;
  &:hover {
    transition: all 300ms ease-in-out;
    background-color: #5f5cbb;
  }
}
</style>
