<template>
  <div class="p">
    <h3>Manage Tickets</h3>
    <!--===================== Manage Buttons Section =====================-->
    <div class="first">
      <div class="manage_buttons">
        <div class="sort">
          <div class="deselectSort btn_sort" v-if="cancelSort">
            <div class="dropdown">
              <button class="dropbtn" v-on:click="deselectSort()">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
          </div>
          <div class="sort_by_type btn_sort">
            <div class="dropdown">
              <button class="dropbtn">Sort by Type: {{ selected_type }}</button>
              <div class="dropdown-content">
                <div
                  v-for="type in Types"
                  v-bind:key="type.ID"
                  v-on:click="getSelectedType(type.Name)"
                >
                  {{ type.Name }}
                </div>
              </div>
            </div>
          </div>
          <div class="sort_by_status btn_sort">
            <div class="dropdown">
              <button class="dropbtn">
                Sort by Status: {{ selected_status }}
              </button>
              <div class="dropdown-content">
                <div
                  v-for="status in Status"
                  v-bind:key="status.ID"
                  v-on:click="getSelectedStatus(status.Status_Name)"
                >
                  {{ status.Status_Name }}
                </div>
              </div>
            </div>
          </div>
          <div class="sort_by_priority btn_sort">
            <div class="dropdown">
              <button class="dropbtn">
                Sort by Priority: {{ selected_priority }}
              </button>
              <div class="dropdown-content">
                <div v-on:click="getSelectedPriority('Low')">Low</div>
                <div v-on:click="getSelectedPriority('Medium')">Medium</div>
                <div v-on:click="getSelectedPriority('High')">High</div>
              </div>
            </div>
          </div>
          <div class="closedTicket btn_sort">
            <div class="dropdown">
              <button
                class="dropbtn"
                id="closedTickets"
                v-on:click="showClosedTickets()"
              >
                Closed tickets
              </button>
            </div>
          </div>
        </div>
        <div class="update" v-if="editOn">
          <div class="update_type btn_update" v-if="editOn">
            <div class="dropdown">
              <button class="dropbtn">Update Type: {{ selected_type }}</button>
              <div class="dropdown-content">
                <div
                  v-for="type in Types"
                  v-bind:key="type.ID"
                  v-on:click="updateType(type.Name)"
                >
                  {{ type.Name }}
                </div>
              </div>
            </div>
          </div>
          <div class="update_status btn_update" v-if="editOn">
            <div class="dropdown">
              <button class="dropbtn">
                Update Status: {{ selected_status }}
              </button>
              <div class="dropdown-content">
                <div
                  v-for="status in Status"
                  v-bind:key="status.ID"
                  v-on:click="updateStatus(status.Status_Name)"
                >
                  {{ status.Status_Name }}
                </div>
              </div>
            </div>
          </div>
          <div class="update_priority btn_update" v-if="editOn">
            <div class="dropdown">
              <button class="dropbtn">
                Update Priority : {{ selected_priority }}
              </button>
              <div class="dropdown-content">
                <div v-on:click="updatePriority('Low')">Low</div>
                <div v-on:click="updatePriority('Medium')">Medium</div>
                <div v-on:click="updatePriority('High')">High</div>
              </div>
            </div>
          </div>
          <div class="update_agent btn_update" v-if="editOn">
            <div class="dropdown">
              <button class="dropbtn">
                Change Agent : {{ selected_agent }}
              </button>
              <div class="dropdown-content">
                <div
                  v-for="agent in Agents"
                  v-bind:key="agent.ID"
                  v-on:click="updateAgent(agent)"
                >
                  {{ agent.Name }}
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="delete btn_update" v-if="editOn">
            <div class="dropdown">
              <button class="dropbtn" v-on:click="deleteTicket()">
                <i class="fa-solid fa-trash"></i>
              </button>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!--===================== Tickets Section =====================-->
    <div class="ticket" v-if="noTickets">There is no Tickets</div>
    <div class="tickets" v-if="!noTickets">
      <div class="ticket1">
        <div class="manage-btn">
          <i class="fa-solid fa-list-check"></i> Manage
        </div>
        <div class="Agent"><i class="fa-regular fa-user"></i> Agent</div>
        <div class="Subject"><i class="fa-solid fa-tag"></i> Subject</div>
        <div class="Date"><i class="fa-regular fa-calendar-days"></i> Date</div>
        <div class="Status"><i class="fa-solid fa-spinner"></i> Status</div>
        <div class="Type"><i class="fa-solid fa-bookmark"></i> Type</div>
        <div class="Priority"><i class="fa-regular fa-flag"></i> Priority</div>
        <div class="View"><i class="fa-regular fa-eye"></i> View</div>
      </div>
      <div
        class="ticket"
        v-for="ticket in Tickets"
        v-bind:key="ticket.ID"
        :id="ticket.ID"
      >
        <div class="manage-btn">
          <button class="manage" v-on:click="editTicket(ticket)">
            <i class="fa-regular fa-pen-to-square"></i>
          </button>
          <button
            class="manage2"
            v-on:click="deselectTicket(ticket.ID)"
            :id="`deSelect-${ticket.ID}`"
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div class="Agent">{{ ticket.AgentName }}</div>
        <div class="Subject">{{ ticket.Ticket_Subject }}</div>
        <div class="Date">{{ ticket.Date.split("T")[0] }}</div>
        <div class="Status">{{ ticket.Status }}</div>
        <div class="Type">{{ ticket.Type }}</div>
        <div class="Priority">{{ ticket.Priority }}</div>
        <div class="View">
          <button v-on:click="viewTicket(ticket.ID)" class="view_link">
            <i class="fa-regular fa-eye"></i>
          </button>
        </div>
      </div>
    </div>
    <!--===================== Message box Section =====================-->
    <div class="Message1">
      <div class="message_contaier">
        <div class="MessContent">
          <p id="msg">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut,
            exercitationem!
          </p>
        </div>
        <div class="closeMsg">
          <button class="closeBtn" v-on:click="closeMsg()">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import jquery from "jquery";
let $ = jquery;
export default {
  data() {
    return {
      Types: [],
      Status: [],
      Tickets: [],
      Agents: [],
      selected_type: "",
      selected_status: "",
      selected_agent: "",
      selected_priority: "",
      cancelSort: "",
      editOn: false,
      ticket: {},
      noTickets: false,
    };
  },
  async mounted() {
    this.$store.dispatch("isAdminLogIN");
    await axios
      .get("/Admin/getTypesAgetStatus", {
        headers: { token: localStorage.getItem("token") },
      })
      .then((res) => {
        this.Types = res.data.types;
        this.Status = res.data.status;
      });
    await axios
      .get("/Admin/getAgents", {
        headers: { token: localStorage.getItem("token") },
      })
      .then((res) => {
        this.Agents = res.data;
      });
    this.loadTickets();
  },
  methods: {
    async loadTickets() {
      await axios
        .get("/Admin/getTickets", {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.msg == "There is no Tickets") {
            this.noTickets = true;
            this.Tickets = [];
          } else {
            this.noTickets = false;
            this.Tickets = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deselectSort() {
      this.selected_type = "";
      this.selected_status = "";
      this.selected_priority = "";
      this.loadTickets();
      this.cancelSort = false;
    },
    async showClosedTickets() {
      await axios
        .get("/Admin/getClosedTickets", {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.msg == "There is no Tickets") {
            this.noTickets = true;
            this.Tickets = [];
            this.cancelSort = true;
          } else {
            this.noTickets = false;
            this.Tickets = res.data;
            this.cancelSort = true;
          }
        });
    },
    async getSelectedType(data) {
      this.selected_type = data;
      this.selected_status = "";
      this.selected_priority = "";
      this.cancelSort = true;
      await axios
        .get(`/Admin/getTickets/Type/${this.selected_type}`, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.msg == "There is no Tickets") {
            this.noTickets = true;
            this.Tickets = [];
          } else {
            this.Tickets = res.data;
          }
        });
    },
    async getSelectedStatus(data) {
      this.selected_status = data;
      this.selected_type = "";
      this.selected_priority = "";
      this.cancelSort = true;
      await axios
        .get(`/Admin/getTickets/Status/${this.selected_status}`, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.msg == "There is no Tickets") {
            this.noTickets = true;
            this.Tickets = [];
          } else {
            this.Tickets = res.data;
          }
        });
    },
    async getSelectedPriority(data) {
      this.selected_priority = data;
      this.selected_status = "";
      this.selected_type = "";
      this.cancelSort = true;
      await axios
        .get(`/Admin/getTickets/Priority/${data}`, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.msg == "There is no Tickets") {
            this.noTickets = true;
            this.Tickets = [];
          } else {
            this.Tickets = res.data;
          }
        });
    },
    async editTicket(ticket) {
      this.editOn = true;
      this.ticket = ticket;
      $(".ticket").css("backgroundColor", "#1f2833");
      $(`#${ticket.ID}`).css("backgroundColor", "#8884ff");
      $(".manage2").fadeOut(200);
      $(`#deSelect-${ticket.ID}`).fadeIn(200);
    },
    async deselectTicket(ID) {
      this.editOn = false;
      $(".ticket").css("backgroundColor", "#1f2833");
      $(`#deSelect-${ID}`).fadeOut(200);
    },
    async updateType(type) {
      await axios
        .patch(
          "/Admin/UpdateTicket/Type",
          {
            ID: this.ticket.ID,
            type: type,
          },
          { headers: { token: localStorage.getItem("token") } }
        )
        .then((res) => {
          if (res.data.msg == "Ticket Type Updated") {
            $("#msg").text("Ticket Type Updated");
            $(".Message1").fadeIn(200);
            setTimeout(() => {
              $(".Message1").fadeOut(400);
            }, 3000);
            this.loadTickets();
          }
        });
    },
    async updateStatus(status) {
      await axios
        .patch(
          "/Admin/UpdateTicket/Status",
          { ID: this.ticket.ID, status: status },
          { headers: { token: localStorage.getItem("token") } }
        )
        .then((res) => {
          if (res.data.msg == "Ticket Status Updated") {
            $("#msg").text("Ticket Type Updated");
            $(".Message1").fadeIn(200);
            setTimeout(() => {
              $(".Message1").fadeOut(400);
            }, 3000);
            this.loadTickets();
          }
        });
    },
    async updatePriority(priority) {
      await axios
        .patch(
          "/Admin/updateTicket/Priority",
          { TicketID: this.ticket.ID, priority: priority },
          { headers: { token: localStorage.getItem("token") } }
        )
        .then((res) => {
          if (res.data.msg == "Ticket Priority Updated") {
            $("#msg").text("Ticket Priority Updated");
            $(".Message1").fadeIn(200);
            setTimeout(() => {
              $(".Message1").fadeOut(400);
            }, 3000);
            this.loadTickets();
          }
        });
    },
    async updateAgent(agent) {
      await axios
        .patch(
          "/Admin/updateTicket/Agent",
          { TicketID: this.ticket.ID, agent: agent },
          { headers: { token: localStorage.getItem("token") } }
        )
        .then((res) => {
          if (res.data.msg == "Agent Updated") {
            $("#msg").text("Agent Updated");
            $(".Message1").fadeIn(200);
            setTimeout(() => {
              $(".Message1").fadeOut(400);
            }, 3000);
            this.loadTickets();
          }
        });
    },
    viewTicket(data) {
      this.$router.push(`/Reply/${data}`);
    },
    // async deleteTicket() {
    //   await axios
    //     .delete(`/Admin/deleteTicket/${this.ticket.ID}`, {
    //       headers: { token: localStorage.getItem("token") },
    //     })
    //     .then((res) => {
    //       if (res.data.msg == "Ticket Deleted") {
    //         this.loadTickets();
    //         this.editOn = false;
    //       }
    //     });
    // },
    closeMsg() {
      $(".Message1").fadeOut(400);
    },
  },
};
</script>

<style lang="scss" scoped>
/******************** Manage Button Section ********************/
.p {
  text-align: left;
  margin: 3rem;
}
.manage_buttons {
  margin-top: 1.2rem;
  margin-left: 2rem;
  display: flex;
  align-items: center;
}
.sort {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  .deselectSort,
  .closedTicket,
  .sort_by_type,
  .sort_by_priority,
  .sort_by_status {
    margin: 1rem;
  }
}
#closedTickets {
  &:hover {
    cursor: pointer;
  }
}
.update {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  .update_type,
  .update_status,
  .update_agent,
  .update_priority,
  .delete {
    margin: 1rem;
  }
}
@media screen and (max-width: 500px) {
  .sort,
  .update {
    grid-template-columns: auto;
  }
}
.dropbtn {
  background-color: #8884ff;
  color: white;
  padding: 16px;
  font-size: 16px;
  border-radius: 10px;
  border: none;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: all 300ms ease-in-out;
}
.dropdown-content div:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown:hover .dropbtn {
  background-color: #6562d1;
}
@media screen and (max-width: 1435px) {
  .manage_buttons {
    display: block;
  }
}
@media screen and (max-width: 1322px) {
  .manage_buttons {
    display: block;
  }
}

/******************** Tickets Section ********************/
.tickets {
  text-align: center;
  width: 100%;
  height: 500px;
  background-color: #1f2833;
  color: #eeeeee;
  box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow-y: scroll;
}
.ticket1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: #1f2833;
  border-bottom: 1px solid #eeeeee;
  div {
    width: 170px;
  }
}
.ticket {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  border-bottom: 0.5px solid #eeeeee;
  div {
    width: 170px;
    overflow-wrap: break-word;
  }
}
.manage-btn {
  width: 160px;
  button {
    margin: 1rem;
  }
}
.manage {
  width: 35px;
  height: 35px;
  color: #eeeeee;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
  background-color: #6562d1;
  &:hover {
    cursor: pointer;
    background-color: #504da7;
  }
}
.manage2 {
  display: none;
  width: 35px;
  height: 35px;
  color: #eeeeee;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
  background-color: #6562d1;
  &:hover {
    cursor: pointer;
    background-color: #504da7;
  }
}
.view_link {
  width: 40px;
  height: 40px;
  padding: 10px;
  background-color: #6562d1;
  color: #eeeeee;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #504da7;
  }
}
@media screen and (max-width: 1352px) {
  .Date,
  .Type {
    display: none;
  }
  .manage-btn {
    button {
      margin: 0.3rem;
    }
  }
}
@media screen and (max-width: 530px) {
  .p {
    margin: 1rem;
  }
  .tickets {
    width: 350px;
  }
  .ticket {
    height: auto;
    div {
      width: calc(350px / 6);
    }
  }
  .manage_buttons {
    margin: 0;
  }
  .sort,
  .update {
    margin: 0;
  }
}
/******************** Meesage box Section ********************/
.Message1 {
  width: 400px;
  height: 100px;
  background-color: #fff;
  position: absolute;
  top: 85%;
  right: 2%;
  border-left: 10px solid #8884ff;
  box-shadow: 0px 0px 20px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
.message_contaier {
  width: 100%;
  height: 100%;
  display: flex;
}
.MessContent {
  display: flex;
  align-items: center;
  width: 350px;
  p {
    margin: 1rem;
  }
}
.closeMsg {
  display: flex;
  align-items: flex-start;
  padding-top: 0;
  padding: 10px;
}
.closeBtn {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  outline: none;
  border: none;
  i {
    color: #1f2833;
    font-size: 22px;
    transition: all 300ms ease-in-out;
  }
}
.closeBtn:hover {
  cursor: pointer;
  i {
    color: #8884ff;
  }
}
</style>
