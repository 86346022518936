<template>
  <div>
    <NavBar />
    <div>
      <div class="box">
        <div class="container">
          <h2 class="title">Your Tickets</h2>
          <button class="addTicket" v-on:click="goToTicket()">
            Create Ticket
          </button>
        </div>
        <div class="Tickets">
          <div class="ticket" v-for="ticket in Tickets" v-bind:key="ticket.ID">
            <router-link :to="`/Ticket/${ticket.ID}`" class="ticket_link">
              <div class="name">{{ ticket.Ticket_Subject }}</div>
              <div class="Date">{{ ticket.Date.split("T")[0] }}</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="update_user_info">
      <h2>User Settings</h2>
      <div class="list">
        <div class="item" v-on:click="showUpdatePassword()">
          Update Password
        </div>
        <div class="item" v-on:click="showUpdateName()">Update Name</div>
      </div>
      <div class="form" id="updatePassword" v-if="UpdatePassword">
        <div class="input">
          <input
            type="password"
            placeholder="Old Password"
            class="text-input"
            v-model="oldPassword"
            id="old_password_input"
          />
          <br />
          <label
            for="old_password_input"
            class="old_password_input_label"
          ></label>
        </div>
        <div class="input">
          <input
            type="password"
            placeholder="New Password"
            class="text-input"
            v-model="newPassword"
            id="new_password_input"
          />
          <br />
          <label
            for="new_password_input"
            class="new_password_input_label"
          ></label>
        </div>
        <div class="input">
          <input
            type="password"
            placeholder="Confirm Password"
            class="text-input"
            v-model="confirmPassword"
            id="confirm_password_input"
          />
          <br />
          <label
            for="confirm_password_input"
            class="confirm_password_input_label"
          ></label>
        </div>
        <div class="input">
          <button class="update_password_btn" v-on:click="updatePassword()">
            Update
          </button>
        </div>
        <div class="input">
          <label class="success_msg_1"></label>
        </div>
      </div>
      <div class="form" id="updateName" v-if="UpdateName">
        <div class="input">
          <input
            type="text"
            placeholder="New name"
            class="text-input"
            v-model="newName"
            id="new_name_input"
          />
          <br />
          <label for="new_name_input" class="new_name_input_label"></label>
        </div>
        <div class="input">
          <button class="update_password_btn" v-on:click="updateName()">
            Update
          </button>
        </div>
        <div class="input">
          <label class="success_msg_2"></label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar.vue";
import axios from "axios";
import jquery from "jquery";
let $ = jquery;
export default {
  data() {
    return {
      Tickets: [],
      UpdatePassword: false,
      UpdateName: false,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      newName: "",
    };
  },
  components: {
    NavBar,
  },
  async mounted() {
    this.$store.dispatch("isLogIN");
    await axios
      .get("/User/getTickets", {
        headers: { token: localStorage.getItem("token") },
      })
      .then((res) => {
        this.Tickets = res.data;
      });
  },
  methods: {
    goToTicket() {
      location.replace("/Ticket");
    },
    showUpdatePassword() {
      this.UpdatePassword = true;
      this.UpdateName = false;
    },
    showUpdateName() {
      this.UpdateName = true;
      this.UpdatePassword = false;
    },
    async updatePassword() {
      $("#old_password_input").click(function () {
        window.onkeydown = function () {
          $("#old_password_input").css("borderBottom", "3px solid #8884ff");
          $(".old_password_input_label").text("");
        };
      });

      $("#new_password_input").click(function () {
        window.onkeydown = function () {
          $("#new_password_input").css("borderBottom", "3px solid #8884ff");
          $(".new_password_input_label").text("");
        };
      });

      $("#confirm_password_input").click(function () {
        window.onkeydown = function () {
          $("#confirm_password_input").css("borderBottom", "3px solid #8884ff");
          $(".confirm_password_input_label").text("");
        };
      });

      if (
        this.oldPassword == "" &&
        this.newPassword == "" &&
        this.confirmPassword == ""
      ) {
        $("#old_password_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".old_password_input_label").text("Please Enter password");

        $("#new_password_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".new_password_input_label").text("Please Enter a new password");

        $("#confirm_password_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".confirm_password_input_label").text("Please Enter a new password");
        return;
      }
      if (this.oldPassword == "") {
        $("#old_password_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".old_password_input_label").text("Please Enter password");
        return;
      }
      if (this.newPassword == "") {
        $("#new_password_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".new_password_input_label").text("Please Enter a new password");
        return;
      }
      if (this.confirmPassword == "") {
        $("#confirm_password_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".confirm_password_input_label").text("Please Enter a new password");
        return;
      }
      if (this.newPassword != this.confirmPassword) {
        $("#confirm_password_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".confirm_password_input_label").text("Passwords don't Match");
        return;
      }
      await axios
        .patch(
          "/User/updatePassword",
          {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
          },
          { headers: { token: localStorage.getItem("token") } }
        )
        .then((res) => {
          if (res.data.err == "Wrong Password") {
            $("#old_password_input").css(
              "borderBottom",
              "3px solid rgb(250,82,82)"
            );
            $(".old_password_input_label").text("Wrong Password");
          }
          if (res.data.msg == "Password Updated") {
            $(".success_msg_1").text("Password Updated");
          }
        });
    },
    async updateName() {
      $("#new_name_input").click(function () {
        window.onkeydown = function () {
          $("#new_name_input").css("borderBottom", "3px solid #8884ff");
          $(".new_name_input_label").text("");
        };
      });
      if (this.newName == "") {
        $("#new_name_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".new_name_input_label").text("Please Enter Name");
        return;
      }
      await axios
        .patch(
          "/User/updateName",
          { newName: this.newName },
          { headers: { token: localStorage.getItem("token") } }
        )
        .then((res) => {
          if (res.data.msg == "Name Updated") {
            $(".success_msg_2").text("Name Updated");
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  margin: 4rem;
}
.container {
  display: flex;
  align-items: center;
}
.title {
  text-align: left;
}
.addTicket {
  width: 100px;
  height: 40px;
  color: #eeeeee;
  background-color: #8884ff;
  margin-left: 1rem;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    color: #8884ff;
    background-color: #eeeeee;
  }
}
.Tickets {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin: 4rem;
}
.ticket {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 1rem;
  margin: 1rem;
  width: 250px;
  height: 80px;
  background-color: #fff;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border-bottom: 5px solid #8884ff;
  transition: all 300ms ease-in-out;
}
.ticket_link {
  height: 80px;
  color: #1f2833;
  transition: all 300ms ease-in-out;
}
.ticket:hover {
  background-color: #1f2833;
  .ticket_link:hover {
    color: #8884ff;
  }
}
@media screen and (max-width: 1240px) {
  .Tickets {
    grid-template-columns: auto auto auto;
  }
}
@media screen and (max-width: 950px) {
  .Tickets {
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 675px) {
  .Tickets {
    grid-template-columns: auto;
  }
}
.update_user_info {
  display: flex;
  justify-content: flex-start;
  margin: 4rem;
}
.list {
  margin: 2rem;
}
.item {
  display: flex;
  margin: 2rem;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    color: #8884ff;
  }
}
.form {
  margin: 2rem;
}
.input {
  padding: 2rem;
  text-align: left;
}
.text-input {
  background: none;
  border: none;
  outline: none;
  width: 365px;
  height: 40px;
  border-bottom: 3px solid #8884ff;
}
.update_password_btn {
  width: 365px;
  height: 40px;
  background-color: #8884ff;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  color: #eeeeee;
  &:hover {
    cursor: pointer;
    background-color: #6360bd;
  }
}
.old_password_input_label,
.new_password_input_label,
.confirm_password_input_label,
.new_name_input_label {
  color: rgb(250, 82, 82);
}
.success_msg_1,
.success_msg_2 {
  color: #1f2833;
}
@media screen and (max-width: 530px) {
  .update_user_info {
    display: block;
  }
  .text-input,
  .update_password_btn {
    width: 240px;
  }
}
@media screen and (max-width: 400px) {
  .update_user_info {
    margin: 0;
  }
  .list {
    margin: 0;
  }
  .Tickets {
    margin: 0;
  }
  .ticket {
    width: 200px;
  }
}
</style>
