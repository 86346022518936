<template>
  <div class="s">
    <div class="contanier">
      <!--===================== sidebar Section =====================-->
      <div class="sidebar" id="Sidebar">
        <div class="item1">
          <div class="item1_box">
            <div class="btn-icon-logo">
              <div class="icon">
                <img :src="Logo" />
              </div>
              <div class="title">{{ Name }}</div>
            </div>
          </div>
          <div class="sidebar_btn">
            <button class="sidebar_bttuon" v-on:click="sidebar()">
              <i class="fa-solid fa-bars"></i>
            </button>
          </div>
        </div>
        <div class="Dashboard">
          <div class="sidebar_item">
            <router-link to="/Admin/Status" class="sidebar_item_link">
              <div class="left">
                <div class="sidebar_item_icon">
                  <i class="fa-solid fa-table-columns"></i>
                </div>
                <div class="sidebar_item_title">Dashboard</div>
              </div>
            </router-link>
          </div>
          <div class="sidebar_Dashboard_hover_title">Dashboard</div>
        </div>
        <div class="tickets">
          <div class="sidebar_item">
            <router-link :to="{ name: 'Tickets' }" class="sidebar_item_link">
              <div class="left">
                <div class="sidebar_item_icon">
                  <i class="fa-regular fa-envelope"></i>
                </div>
                <div class="sidebar_item_title">Tickets</div>
              </div>
            </router-link>
          </div>
          <div class="sidebar_tickets_hover_title">Tickets</div>
        </div>
        <div class="Knowledge_base">
          <div class="sidebar_item">
            <router-link
              :to="{ name: 'knowledgeBase' }"
              class="sidebar_item_link"
            >
              <div class="left">
                <div class="sidebar_item_icon">
                  <i class="fa-solid fa-book"></i>
                </div>
                <div class="sidebar_item_title">Knowledge base</div>
              </div>
            </router-link>
          </div>
          <div class="sidebar_Knowledgebase_hover_title">Knowledge base</div>
        </div>
        <div class="Manage">
          <div class="sidebar_item">
            <router-link :to="{ name: 'Dashboard' }" class="sidebar_item_link">
              <div class="left">
                <div class="sidebar_item_icon">
                  <i class="fa-solid fa-pen-nib"></i>
                </div>
                <div class="sidebar_item_title">Manage</div>
              </div>
            </router-link>
          </div>
          <div class="sidebar_Manage_hover_title">Manage</div>
        </div>
        <div class="ManageAgents">
          <div class="sidebar_item">
            <router-link
              :to="{ name: 'ManageAgents' }"
              class="sidebar_item_link"
            >
              <div class="left">
                <div class="sidebar_item_icon">
                  <i class="fa-solid fa-pen-nib"></i>
                </div>
                <div class="sidebar_item_title">Manage Agents</div>
              </div>
            </router-link>
          </div>
          <div class="sidebar_Manage_Agents_hover_title">Manage Agents</div>
        </div>
        <div class="Logout" v-on:click="Logout()">
          <div class="sidebar_item">
            <div to="" class="sidebar_item_link">
              <div class="left">
                <div class="sidebar_item_icon">
                  <i class="fa-solid fa-right-from-bracket fa-rotate-180"></i>
                </div>
                <div class="sidebar_item_title">Logout</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--===================== router Section =====================-->
      <div class="page">
        <div class="navbar">
          <div class="admin_info">
            <h3>{{ AdminName }}</h3>
            <div v-on:click="Logout()" class="logout_link">Logout</div>
          </div>
        </div>
        <div class="ss">
          <header>
            <div class="Icon">
              <div class="logo">
                <img :src="Logo" />
              </div>
              <div class="Ctitle">
                <span>{{ Name }}</span>
              </div>
            </div>
            <div class="Links">
              <ul>
                <li>
                  <router-link to="/Admin/Status">Dashboard</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Tickets' }">Tickets</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'knowledgeBase' }"
                    >Knowledge base</router-link
                  >
                </li>
                <li>
                  <router-link :to="{ name: 'Dashboard' }">Manage</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'ManageAgents' }" class="sign_up"
                    >Manage Agents</router-link
                  >
                </li>
                <li v-on:click="Logout()">
                  <div class="Logout_link">Logout</div>
                </li>
              </ul>
              <button class="show_nav" v-on:click="openNav()">
                <div class="line1"></div>
                <div class="line2"></div>
                <div class="line3"></div>
              </button>
            </div>
          </header>
          <div class="mobile-nav">
            <ul>
              <li>
                <router-link to="/Admin/Status">Dashboard</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Tickets' }">Tickets</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'knowledgeBase' }"
                  >Knowledge base</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'Dashboard' }">Manage</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'ManageAgents' }" class="sign_up"
                  >Manage Agents</router-link
                >
              </li>
              <li v-on:click="Logout()">
                <div class="Logout_link">Logout</div>
              </li>
            </ul>
          </div>
          <transition name="fade" mode="out-in">
            <router-view />
          </transition>
        </div>
      </div>
    </div>
    <!-- <div class="mobile">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      Logo: "",
      Name: "",
      AdminName: "",
      sidebarClose: false,
      Status: [],
    };
  },
  async mounted() {
    this.$store.dispatch("isAdminLogIN");
    await axios
      .get("/Admin/AdminDash", {
        headers: { token: localStorage.getItem("token") },
      })
      .then((res) => {
        this.AdminName = res.data.username;
      });
    await axios.get("/Admin/getLogo").then(async (res) => {
      this.Logo = `/uploads/${await res.data.Image}`;
      this.Name = await res.data.websiteName;
    });
  },
  methods: {
    sidebar() {
      let sidebar = document.getElementById("Sidebar");
      let sidebar_bttuon = document.getElementsByClassName("sidebar_btn")[0];
      let sidebar_title = document.getElementsByClassName("title")[0];
      let sidebar_logo = document.getElementsByClassName("btn-icon-logo")[0];
      let sidebar_item_title =
        document.getElementsByClassName("sidebar_item_title");
      let ss = document.getElementsByClassName("ss")[0];
      if (this.sidebarClose == false) {
        sidebar.classList.add("closeSidebar");
        sidebar_bttuon.classList.add("move_button");
        sidebar_title.classList.add("hide");
        sidebar_logo.classList.add("center");
        for (let i = 0; i < sidebar_item_title.length; i++) {
          sidebar_item_title[i].classList.add("hide");
        }
        setTimeout(() => {
          ss.classList.add("ss_change");
        }, 1000);
        this.sidebarClose = true;
      } else {
        sidebar.classList.remove("closeSidebar");
        sidebar_bttuon.classList.remove("move_button");
        sidebar_title.classList.remove("hide");
        sidebar_logo.classList.remove("center");
        for (let i = 0; i < sidebar_item_title.length; i++) {
          sidebar_item_title[i].classList.remove("hide");
        }
        ss.classList.remove("ss_change");
        this.sidebarClose = false;
      }
    },
    openNav() {
      let nav = document.getElementsByClassName("mobile-nav")[0];
      if (nav.classList[1] != "show") {
        nav.classList.add("show");
      } else {
        nav.classList.remove("show");
      }
    },
    Logout() {
      this.$store.dispatch("Logout");
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.contanier {
  display: flex;
  height: 100%;
}
.page {
  width: calc(100% + 300px);
}
.ss {
  margin-left: 300px;
  height: 100vh;
  width: calc(100% - 300px);
}
.ss_change {
  margin-left: 100px;
  width: calc(100% - 100px);
}
/******************** Sidebar Section ********************/
/******************** Sidebar top Section ********************/
.sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 100%;
  background-color: #1f2833;
  z-index: 3000;
}
.Dashboard {
  display: flex;
  align-items: center;
  &:hover {
    .sidebar_Dashboard_hover_title {
      display: block;
      opacity: 1;
    }
  }
}
.tickets {
  display: flex;
  align-items: center;
  &:hover {
    .sidebar_tickets_hover_title {
      display: block;
      opacity: 1;
    }
  }
}
.Knowledge_base {
  display: flex;
  align-items: center;
  &:hover {
    .sidebar_Knowledgebase_hover_title {
      display: block;
      opacity: 1;
    }
  }
}
.Manage {
  display: flex;
  align-items: center;
  &:hover {
    .sidebar_Manage_hover_title {
      display: block;
      opacity: 1;
    }
  }
}
.ManageAgents {
  display: flex;
  align-items: center;
  &:hover {
    .sidebar_Manage_Agents_hover_title {
      display: block;
      opacity: 1;
    }
  }
}
.sidebar_Dashboard_hover_title,
.sidebar_tickets_hover_title,
.sidebar_Knowledgebase_hover_title,
.sidebar_Manage_hover_title,
.sidebar_Manage_Agents_hover_title {
  margin-left: 1rem;
  display: inline-block;
  color: #eeeeee;
  background-color: #343c45;
  padding: 0.5rem;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
  display: none;
  opacity: 0;
}
.Logout {
  margin-top: auto;
}
.item1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item1_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-icon-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem;
  width: 300px;
}
.icon {
  width: 50px;
  height: 50px;
  img {
    width: 100%;
    height: 100%;
  }
}
.title {
  color: #eeeeee;
  margin-left: 1rem;
}
.sidebar_btn {
  transform: translateX(-35px);
}
.sidebar_bttuon {
  width: 50px;
  height: 50px;
  background: none;
  border: none;
  outline: none;
  border-radius: 100px;
  background: #e7e7e7;
  transition: all 250ms ease-in-out;
  font-size: 16px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
  &:hover {
    cursor: pointer;
    color: #eeeeee;
    background-color: #8884ff;
  }
}
/******************** Sidebar animate classes Section ********************/
.openSidebar {
  animation: widthincrease 2s;
  animation-fill-mode: forwards;
}
@keyframes widthincrease {
  from {
    width: 100px;
  }
  to {
    width: 300px;
  }
}
.closeSidebar {
  animation: widthdecrease 2s;
  animation-fill-mode: forwards;
}
@keyframes widthdecrease {
  from {
    width: 300px;
  }
  to {
    width: 100px;
  }
}
.move_button {
  animation: move 2s;
  animation-fill-mode: forwards;
}
.hide {
  display: none;
}
.center {
  transform: translateX(25px);
}
@keyframes move {
  from {
    transform: translateX(-35px);
  }
  to {
    transform: translateX(-130px);
  }
}

/******************** Sidebar animate classes Section ********************/
/******************** Sidebar top Section ********************/
/******************** Sidebar Items Section ********************/
.sidebar_item {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 80px;
  transition: all 250ms ease-out;
}
.sidebar_item:hover {
  background-color: #8884ff;
}
.sidebar_item_link.router-link-active {
  background-color: #8884ff;
}
.sidebar_item_link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.left {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
.sidebar_item_title {
  text-align: left;
  color: #eeeeee;
  width: 200px;
}
.sidebar_item_icon {
  font-size: 28px;
  color: #eeeeee;
  width: 100px;
}
.right {
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    font-size: 20px;
    background: none;
    color: #eeeeee;
    outline: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    transition: all 300ms ease-in-out;
    &:hover {
      color: #eeeeee;
      cursor: pointer;
      transform: rotate(180deg);
    }
  }
}
@media screen and (max-width: 700px) {
  .ss {
    margin-left: 100px;
    width: calc(100% - 100px);
  }
  .sidebar {
    width: 100px;
  }
  .sidebar_bttuon {
    display: none;
  }
  .btn-icon-logo {
    transform: translateX(5px);
  }
  .title {
    display: none;
  }
  .sidebar_item_title {
    display: none;
  }
}
/******************** Sidebar Items Section ********************/
/**********************************************************/
header {
  width: 100%;
  height: 80px;
  background: #1f2833;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: none;
}
.Icon {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.logo {
  img {
    width: 40px;
    height: 40px;
  }
}
.Ctitle {
  color: #eeeeee;
  font-weight: 900;
  font-size: 18px;
  margin-left: 10px;
}
.Links ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  li a {
    font-weight: 800;
    margin: 2rem;
    color: #eeeeee;
    transition: all 0.35s ease-out;
    &:hover {
      color: #8884ff;
    }
  }
}
.Logout_link {
  font-weight: 800;
  color: #eeeeee;
  transition: all 0.35s ease-out;
  &:hover {
    cursor: pointer;
    color: #8884ff;
  }
}
.show_nav {
  background: none;
  border: none;
  outline: none;
  direction: rtl;
  display: none;
  width: 40px;
  height: 40px;
  div {
    margin: 9px;
    text-align: right;
  }
}
.line1,
.line3 {
  width: 30px;
  height: 2px;
  background-color: #eeeeee;
  transition: all 200ms ease-in-out;
}
.line2 {
  width: 25px;
  height: 2px;
  background-color: #eeeeee;
  transition: all 200ms ease-in-out;
}
.show_nav:hover {
  cursor: pointer;
}
.mobile-nav {
  ul {
    display: none;
  }
  ul li {
    opacity: 0;
    padding: 10px;
  }
  li a {
    font-weight: 800;
    color: #eeeeee;
    transition: all 0.35s ease-out;
    &:hover {
      color: #8884ff;
    }
  }
  ul li a {
    display: none;
  }
}
.show {
  background-color: #1f2833;
  width: 100%;
  ul {
    display: block;
  }
  ul li {
    opacity: 1;
  }
  ul li a {
    display: block;
  }
}
@media screen and (max-width: 1200px) {
  .Links ul {
    display: none;
  }
  .show_nav {
    display: block;
  }
}
@media screen and (min-width: 1200px) {
  .show {
    display: none;
  }
}
/**********************************************************/
.navbar {
  background-color: #fdfdfd;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0px 0px 4px 7px rgba(0, 0, 0, 0.1);
}
.admin_info {
  text-align: left;
  margin-right: 4rem;
  color: #1f2833;
}
.logout_link {
  color: #1f2833;
  transition: all 150ms ease-out;
  &:hover {
    cursor: pointer;
    color: #2024ff;
  }
}
.mobile {
  display: none;
}
@media screen and (max-width: 450px) {
  #Sidebar {
    display: none;
  }
  header {
    display: flex;
  }
  .navbar {
    display: none;
  }
  .ss {
    margin: 0;
    width: 100%;
  }
}
</style>
