<template>
  <div>
    <!--===================== Categories Section =====================-->
    <div>
      <div class="title">
        <h2>Categories</h2>
        <button class="addc_Btn" v-on:click="showAddCategoryPopup()">
          <i class="fa-solid fa-plus"></i> Add
        </button>
      </div>
      <div class="Categories">
        <div
          class="category"
          v-for="category in Categories"
          v-bind:key="category.ID"
        >
          <router-link :to="`Category/${category.ID}`" class="category_link">
            <div class="num">{{ category.articlesCount }}</div>
            <div class="category_name">{{ category.CategoryName }}</div>
          </router-link>
          <div class="btns">
            <button v-on:click="showEditCategoryPopup(category)">
              <i class="fa-regular fa-pen-to-square"></i>
            </button>
            <button v-on:click="deleteCategory(category)">
              <i class="fa-solid fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--===================== Add Category Popup Section =====================-->
    <div v-if="addCategoryPopup">
      <div class="add_category_popup">
        <div class="dispage">
          <div class="form">
            <div class="close">
              <button v-on:click="hideAddCategoryPopup()">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div class="input">
              <h2>Add Category</h2>
            </div>
            <div class="input">
              <input
                type="text"
                placeholder="Category Name"
                v-model="CategoryName"
                id="name_input"
                class="category_name_input"
              />
              <br />
              <label for="name_input" class="category_name_input_label"></label>
            </div>
            <div class="input">
              <button class="add_btn_popup" v-on:click="addCategory()">
                Add Category
              </button>
            </div>
            <div class="input">
              <label class="success_msg_1"></label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--===================== Edit Category Popup Section =====================-->
    <div v-if="editCategoryPopup">
      <div class="edit_category_popup">
        <div class="dispage">
          <div class="form">
            <div class="close">
              <button v-on:click="hideEditCategoryPopup()">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div class="input">
              <h2>Edit Category</h2>
            </div>
            <div class="input">
              <input
                type="text"
                placeholder="Category Name"
                v-model="NCategoryName"
                id="name_input"
                class="new_category_name_input"
              />
              <br />
              <label
                for="name_input"
                class="new_category_name_input_label"
              ></label>
            </div>
            <div class="input">
              <button class="add_btn_popup" v-on:click="editCategory()">
                Edit
              </button>
            </div>
            <div class="input">
              <label class="success_msg_2"></label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import jquery from "jquery";
let $ = jquery;
export default {
  data() {
    return {
      Categories: [],
      addCategoryPopup: false,
      editCategoryPopup: false,
      CategoryName: "",
      NCategoryName: "",
      Category: {},
    };
  },
  async mounted() {
    this.$store.dispatch("isAdminLogIN");
    this.loadCategories();
  },
  methods: {
    async loadCategories() {
      await axios
        .get("/Admin/getCategoriesAndArticles", {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          this.Categories = res.data;
        });
    },
    showAddCategoryPopup() {
      this.addCategoryPopup = true;
      setTimeout(() => {
        $(".add_category_popup").fadeIn(200);
      }, 0);
    },
    hideAddCategoryPopup() {
      $(".add_category_popup").fadeOut(200);
      setTimeout(() => {
        this.addCategoryPopup = false;
      }, 1000);
    },
    showEditCategoryPopup(Category) {
      this.Category = Category;
      this.NCategoryName = Category.CategoryName;
      this.editCategoryPopup = true;
      setTimeout(() => {
        $(".edit_category_popup").fadeIn(200);
      }, 0);
    },
    hideEditCategoryPopup() {
      $(".edit_category_popup").fadeOut(200);
      setTimeout(() => {
        this.editCategoryPopup = false;
      }, 1000);
    },
    async addCategory() {
      $(".category_name_input").click(function () {
        window.onkeydown = function () {
          $(".category_name_input").css("borderBottom", "3px solid #8884ff");
          $(".category_name_input_label").text("");
        };
      });
      if (this.CategoryName == "") {
        $(".category_name_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".category_name_input_label").text("Please Enter a Category Name");
        return;
      }
      await axios
        .post(
          "/Admin/addCategory",
          { CategoryName: this.CategoryName },
          { headers: { token: localStorage.getItem("token") } }
        )
        .then((res) => {
          if (res.data.msg == "Category Created") {
            $(".success_msg_1").text("Category Created");
            this.loadCategories();
          }
        });
    },
    async editCategory() {
      $(".new_category_name_input").click(function () {
        window.onkeydown = function () {
          $(".new_category_name_input").css(
            "borderBottom",
            "3px solid #8884ff"
          );
          $(".new_category_name_input_label").text("");
        };
      });
      if (this.NCategoryName == "") {
        $(".new_category_name_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".new_category_name_input_label").text(
          "Please Enter a Category Name"
        );
        return;
      }
      await axios
        .patch(
          `/Admin/editCategory/${this.Category.ID}`,
          { NewCategoryName: this.NCategoryName },
          { headers: { token: localStorage.getItem("token") } }
        )
        .then((res) => {
          if (res.data.msg == "Category Updated") {
            $(".success_msg_2").text("Category Updated");
            this.loadCategories();
          }
          if (res.data.msg == "Category Not found") {
            $(".success_msg_2").text("Category Not found");
          }
        });
    },
    async deleteCategory(category) {
      await axios
        .delete(`/Admin/deleteCategory/${category.ID}`, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          console.log(res);
          if (
            res.data.msg ==
            "Category And all articles inside the category are deleted"
          ) {
            for (let i = 0; i < this.Categories.length; i++) {
              if (category.ID == this.Categories[i].ID) {
                this.Categories.splice(i, 1);
              }
            }
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: #1f2833;
}
.title {
  display: flex;
  align-items: center;
  margin: 4rem;
  text-align: left;
}
.addc_Btn {
  width: 80px;
  height: 40px;
  background-color: #8884ff;
  color: #eee;
  margin-left: 1rem;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #6562d1;
  }
}
.Categories {
  margin: 4rem;
  display: grid;
  grid-template-columns: auto auto auto auto;
}
.category {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 300px;
  height: 70px;
  margin: 1rem;
  background-color: #fff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border-bottom: 3px solid #8884ff;
  transition: all 350ms ease-in-out;
  overflow-wrap: break-word;
}
.category_link {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: all 350ms ease-in-out;
}
.category:hover {
  .category_link {
    color: #8884ff;
  }
  background-color: #1f2833;
  .btns {
    button {
      opacity: 1;
    }
  }
}
.btns {
  display: flex;
  button {
    opacity: 0;
    background-color: #8884ff;
    width: 30px;
    height: 30px;
    border-radius: 100px;
    color: #eeeeee;
    margin: 1rem;
    transition: all 300ms ease-in-out;
    &:hover {
      cursor: pointer;
      background-color: #eeeeee;
      color: #8884ff;
    }
  }
}
@media screen and (max-width: 1650px) {
  .Categories {
    grid-template-columns: auto auto auto;
  }
}
@media screen and (max-width: 1420px) {
  .Categories {
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 1098px) {
  .Categories {
    grid-template-columns: auto;
  }
}
@media screen and (max-width: 575px) {
  .category {
    width: 200px;
  }
}
@media screen and (max-width: 450px) {
  .title {
    margin: 2rem;
  }
  .Categories {
    margin: 2rem;
  }
}
@media screen and (max-width: 450px) {
  .category {
    width: 180px;
  }
  .title {
    margin: 0.5rem;
  }
  .Categories {
    margin: 0.5rem;
  }
}
/************************ Popup Section ************************/
.add_category_popup,
.edit_category_popup {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background: #12181e87;
  z-index: 3500;
  display: flex;
  flex-direction: column;
  display: none;
}
.dispage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 3000;
}
.form {
  width: 400px;
  height: 350px;
  background-color: #1f2833;
  border-radius: 15px;
}
.close {
  text-align: right;
  margin: 1rem;
  button {
    color: #eeeeee;
    transition: all 300ms ease-in-out;
    i {
      font-size: 26px;
    }
    &:hover {
      cursor: pointer;
      color: #8884ff;
    }
  }
}
.input {
  padding: 1rem;
  h2 {
    color: #eeeeee;
  }
}
.category_name_input,
.new_category_name_input {
  background: none;
  border: none;
  outline: none;
  border-bottom: 3px solid #8884ff;
  width: 265px;
  height: 30px;
  color: #eeeeee;
  font-size: 15px;
}
.category_name_input_label,
.new_category_name_input_label {
  color: rgb(250, 82, 82);
}
.add_btn_popup {
  background-color: #8884ff;
  width: 265px;
  height: 40px;
  color: #eeeeee;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #6c69c8;
  }
}
.success_msg_1,
.success_msg_2,
.success_msg_3,
.success_msg_4 {
  color: #eeeeee;
}
.success_msg_5 {
  color: #1f2833;
}
</style>
