<template>
  <div class="home">
    <!--===================== Navbar Section =====================-->
    <NavBar />
    <!--===================== Welcome Section =====================-->
    <div class="welcome_search" id="welcome">
      <h3 class="Knowledge_title">Knowledgebase</h3>
      <div class="lists">
        <ul v-if="articles1.length != 0">
          <h3>{{ Category1 }}</h3>
          <li
            class="article"
            v-for="article in articles1"
            v-bind:key="article.ID"
          >
            <router-link :to="`/Article/${article.ID}`">{{
              article.Title
            }}</router-link>
          </li>
        </ul>
        <ul v-if="articles2.length != 0">
          <h3>{{ Category2 }}</h3>

          <li
            class="article"
            v-for="article in articles2"
            v-bind:key="article.ID"
          >
            <router-link :to="`/Article/${article.ID}`">{{
              article.Title
            }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <!--===================== Knowledge base Section =====================-->
    <div class="knowledge">
      <div>
        <h2>Categories</h2>
        <div class="Categories">
          <div
            class="category"
            v-for="category in Categories"
            v-bind:key="category.ID"
          >
            <div class="category_title">
              <div class="dot"></div>
              <router-link :to="`/Articles/${category.ID}`">{{
                category.CategoryName
              }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--===================== Contact Us Section =====================-->
    <div class="contact_us">
      <div class="container">
        <div class="contact_us_icon">
          <img src="../assets/Contact_us.svg" />
        </div>
        <div class="contact_us_content">
          <div>
            <p>
              If you have any questions, please click the button below or check
              the articles above.
            </p>
            <div class="link">
              <router-link to="/Ticket" class="contact_us_link"
                >Contact us <i class="fa-regular fa-paper-plane"></i
              ></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--===================== Footer Section =====================-->
    <div class="footer">
      <div class="footer-box">
        <div class="line"></div>
        <div class="links">
          <ul>
            <li>
              <router-link to="/">Home</router-link>
            </li>
            <li><div class="dot1"></div></li>
            <li>
              <router-link :to="`/Articles/${Categories[0].ID}`"
                >Articles</router-link
              >
            </li>
            <li><div class="dot1"></div></li>
            <li>
              <router-link to="/Ticket">Contact us</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";
import axios from "axios";
export default {
  name: "HomeView",
  components: {
    NavBar,
  },
  data() {
    return {
      Categories: [],
      Category1: "",
      articles1: [],
      Category2: "",
      articles2: [],
    };
  },
  async mounted() {
    await axios.get("/User/getCategories").then((res) => {
      this.Categories = res.data;
      this.Category1 = this.Categories[0].Name;
      this.Category2 = this.Categories[1].Name;
    });
    await axios
      .get(`/User/getArticles/${this.Categories[0].ID}`)
      .then((res) => {
        this.articles1 = res.data;
      });
    await axios
      .get(`/User/getArticles/${this.Categories[1].ID}`)
      .then((res) => {
        this.articles2 = res.data;
      });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
/******************** Welcome Section ********************/
.welcome_search {
  height: 500px;
  background-color: #9b98f0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
.Knowledge_title {
  color: #eeeeee;
  font-weight: 600;
  font-size: 22px;
}
.lists {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.article {
  text-align: left;
  margin: 2rem;
  a {
    color: #eeeeee;
    font-size: 16px;
    transition: all 300ms ease-in-out;
    &:hover {
      color: #3f3d76;
    }
  }
}
.title {
  color: #fff;
  margin-bottom: 2rem;
}
.search_input {
  width: 500px;
  height: 50px;
  background: none;
  border: none;
  outline: none;
  color: #1f2833;
  background-color: #fff;
  padding: 1rem;
  font-size: 16px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.search_btn {
  background: none;
  border: none;
  outline: none;
  background-color: #5e59eb;
  width: 50px;
  height: 50px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #fff;
  transition: all 300ms ease-in-out;
}
.search_btn:hover {
  cursor: pointer;
  background-color: #4b47bd;
}
@media screen and (max-width: 540px) {
  .search_input {
    width: 300px;
  }
}
@media screen and (max-width: 400px) {
  .search_input {
    width: 200px;
  }
}
/******************** Knowledge base Section ********************/
.knowledge {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  h2 {
    color: #5d576b;
  }
}
.Categories {
  display: grid;
  grid-template-columns: auto auto auto auto;
}
.category {
  width: 300px;
  height: 40px;
  margin: 2rem;
  background-color: #8884ff;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  transition: all 300ms ease-in-out;
}
.category_title {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #eeeeee;
  a {
    color: #eeeeee;
    transition: all 150ms ease-in-out;
  }
}
.dot,
.dot1 {
  width: 5px;
  height: 5px;
  background-color: #eeeeee;
  border-radius: 100px;
  margin-right: 1rem;
  transition: all 150ms ease-in-out;
}
.category:hover {
  transform: scale(1.2);
  .dot {
    background-color: #1f2833;
  }
  a {
    color: #1f2833;
  }
}
@media screen and (max-width: 1500px) {
  .Categories {
    grid-template-columns: auto auto auto;
  }
}
@media screen and (max-width: 1100px) {
  .Categories {
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 743px) {
  .Categories {
    grid-template-columns: auto;
  }
}
@media screen and (max-width: 360px) {
  .category {
    width: 250px;
  }
}
/******************** Contact Us Section ********************/
.contact_us {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.contact_us_icon {
  width: 400px;
  height: 400px;
  img {
    width: 100%;
    height: 100%;
  }
}
.contact_us_content {
  display: flex;
  align-items: center;
  width: 300px;
  height: 400px;
  text-align: left;
  p {
    text-align: justify;
    font-size: 18px;
    font-weight: 600;
  }
}
.link {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5e59eb;
  color: #eeeeee;
  width: 110px;
  height: 40px;
  margin-top: 2rem;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #4b47bd;
  }
}
.contact_us_link {
  color: #eeeeee;
  font-weight: 500;
}
@media screen and (max-width: 772px) {
  .container {
    display: block;
  }
  .contact_us_icon {
    width: 250px;
    height: 250px;
  }
  .contact_us_content {
    width: 250px;
  }
}
/******************** Footer Section ********************/
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.line {
  width: 1000px;
  height: 0.2px;
  background-color: #5d576b;
}
.links {
  padding: 0.4rem;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ul li {
    display: inline;
    margin: 3.2rem;
    a {
      color: #1f2833;
    }
  }
}
.dot1 {
  background-color: #1f2833;
}
@media screen and (max-width: 1020px) {
  .line {
    width: 800px;
  }
}
@media screen and (max-width: 820px) {
  .line {
    width: 600px;
  }
}
@media screen and (max-width: 750px) {
  .line {
    width: 600px;
  }
  .links {
    padding: 0.4rem;
    ul li {
      display: inline;
      margin: 1.2rem;
    }
  }
}
@media screen and (max-width: 617px) {
  .line {
    width: 400px;
  }
}
@media screen and (max-width: 500px) {
  .line {
    width: 300px;
  }
  .links {
    padding: 0.2rem;
    ul li {
      display: inline;
      margin: 0.2rem;
    }
  }
}
</style>
