import { render, staticRenderFns } from "./ArticlesView.vue?vue&type=template&id=2365a6cf&scoped=true&"
import script from "./ArticlesView.vue?vue&type=script&lang=js&"
export * from "./ArticlesView.vue?vue&type=script&lang=js&"
import style0 from "./ArticlesView.vue?vue&type=style&index=0&id=2365a6cf&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2365a6cf",
  null
  
)

export default component.exports