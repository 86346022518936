<template>
  <div>
    <div class="form">
      <div class="left">
        <div class="top">
          <router-link to="/" class="back_link"
            ><i class="fa-solid fa-arrow-left"></i> Back to Home</router-link
          >
        </div>
        <div class="mid">
          <form @submit.prevent="Register()">
            <h3 class="title">Welcome</h3>
            <div class="input">
              <div>
                <input
                  type="text"
                  name="name"
                  autocomplete="off"
                  v-model="name"
                  class="name-input"
                  placeholder="Name"
                />
                <br />
                <label for="name" class="error_msg_0"></label>
              </div>
            </div>
            <div class="input">
              <div>
                <input
                  type="text"
                  name="email"
                  autocomplete="off"
                  v-model="email"
                  class="email-input"
                  placeholder="E-mail"
                />
                <br />
                <label for="email" class="error_msg_1"></label>
              </div>
            </div>
            <div class="input">
              <div>
                <input
                  type="password"
                  name="password"
                  autocomplete="off"
                  v-model="password"
                  class="password-input"
                  placeholder="Password"
                />
                <br />
                <label for="password" class="error_msg_2"></label>
              </div>
            </div>
            <div class="links">
              <router-link to="/Login">Already have an Account</router-link>
            </div>
            <div class="input">
              <button class="submit">Sign up</button>
            </div>
          </form>
        </div>
        <div class="bottom">
          <ul>
            <li class="link1">
              <router-link to="/">Home</router-link>
            </li>
            <li><div class="dot"></div></li>
            <li class="link3">
              <router-link to="">Zimam</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="right">
        <div class="one"></div>
        <div class="one"></div>
        <div class="one"></div>
        <div class="one"></div>
        <div class="one"></div>
      </div>
    </div>
  </div>
</template>

<script>
import JQuery from "jquery";
import axios from "axios";
let $ = JQuery;
export default {
  name: "LoginView",
  data() {
    return {
      name: "",
      email: "",
      password: "",
    };
  },
  methods: {
    async Register() {
      $(".name-input").click(function () {
        window.onkeydown = function () {
          $(".name-input").css("borderBottom", "3px solid #8884ff");
          $(".error_msg_0").text("");
        };
      });
      $(".email-input").click(function () {
        window.onkeydown = function () {
          $(".email-input").css("borderBottom", "3px solid #8884ff");
          $(".error_msg_1").text("");
        };
      });
      $(".password-input").click(function () {
        window.onkeydown = function () {
          $(".password-input").css("borderBottom", "3px solid #8884ff");
          $(".error_msg_2").text("");
        };
      });
      if (this.name == "" && this.email == "" && this.password == "") {
        $(".name-input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_0").text("Please Enter a Name");

        $(".email-input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_1").text("Please Enter an E-mail");

        $(".password-input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_2").text("Please Enter a Password");
        return;
      }
      if (this.name == "") {
        $(".error_msg_0").text("Please Enter a Name");
        $(".name-input").css("borderBottom", "3px solid rgb(250,82,82)");
        return;
      }
      if (this.email == "") {
        $(".error_msg_1").text("Please Enter an E-mail");
        $(".email-input").css("borderBottom", "3px solid rgb(250,82,82)");
        return;
      }
      if (this.password == "") {
        $(".error_msg_2").text("Please Enter a Password");
        $(".password-input").css("borderBottom", "3px solid rgb(250,82,82)");
        return;
      }
      await axios
        .post("/Auth/Register", {
          Name: this.name,
          Email: this.email,
          Password: this.password,
        })
        .then((res) => {
          if (res.data.err == "The Email Already exists") {
            $(".error_msg_1").text("The Email Already in use");
            $(".email-input").css("borderBottom", "3px solid rgb(250,82,82)");
          }
          if (res.data.msg == "User Created") {
            localStorage.setItem("token", res.data.token);
            location.replace("/Login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: #1f2833;
  font-weight: bold;
  font-size: 14px;
  margin: 20px;
  transition: all 200ms ease-in-out;
}
a:hover {
  color: #8884ff;
}
.form {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.562);
}
/********************* Left side *********************/
.left {
  width: 500px;
  height: 100%;
}
.top {
  display: flex;
  align-items: center;
  height: 10vh;
}
.back_link {
  font-size: 18px;
  font-weight: 700;
}
.mid {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.title {
  font-size: 32px;
  font-weight: 900;
}
.input {
  padding: 20px;
  div {
    text-align: left;
  }
}
.name-input,
.email-input,
.password-input {
  background: none;
  border: none;
  outline: none;
  width: 365px;
  height: 40px;
  padding: 6px;
  font-size: 15px;
  border-bottom: 3px solid #8884ff;
  transition: all 200ms ease-in-out;
  color: #1f2833;
  font-weight: 700;
}
::placeholder {
  color: #1f2833;
  font-weight: 700;
}
.error_msg_1,
.error_msg_2 {
  color: rgb(250, 82, 82);
  font-weight: 700;
}
.links {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.submit {
  width: 365px;
  height: 40px;
  padding: 6px;
  font-size: 15px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #8884ff;
  color: #eeeeee;
  transition: all 300ms ease-in-out;
}
.submit:hover {
  cursor: pointer;
  background-color: #5f5cbd;
}
.bottom {
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      display: inline;
      margin-left: 1.2rem;
    }
  }
}
.dot {
  color: #1f2833;
  background-color: #1f2833;
  width: 5px;
  height: 5px;
  border-radius: 360px;
}
/********************* Left side *********************/
/********************* right side *********************/
.right {
  display: flex;
  justify-content: space-between;
  width: 600px;
}
.one {
  background-color: #8884ff;
  height: 5000px;
  width: 130px;
  margin: 1rem;
  transform: rotate(30deg);
}
/********************* right side *********************/
@media screen and (max-width: 1410px) {
  .right {
    display: none;
  }
}
@media screen and (max-width: 420px) {
  .left {
    width: 300px;
  }
  .email-input,
  .password-input {
    width: 265px;
  }
  .submit {
    width: 265px;
  }
  .dot {
    display: none;
  }
}
</style>
