<template>
  <div>
    <div>
      <div class="ss">
        <h2>Articles</h2>
        <button class="addc_Btn" v-on:click="showAddArticle()">
          <i class="fa-solid fa-plus"></i> Add
        </button>
      </div>
      <!--===================== Add Article Section =====================-->
      <div class="add_article" v-if="AddArticle">
        <h3>Add Article</h3>
        <div class="input">
          <div>
            <input
              type="text"
              name="Title"
              placeholder="Title"
              v-model="Title"
              class="title_input"
            />
            <br />
            <label for="Title" class="title_input_label"></label>
          </div>
        </div>
        <div class="input">
          <div class="rich_text" id="rich">
            <vue-editor v-model="ArticleContent"></vue-editor>
            <label class="rich_text_label"></label>
          </div>
        </div>
        <div class="input">
          <button class="add_article_btn" v-on:click="addArticle()">
            Add Article
          </button>
        </div>
      </div>
      <!--===================== Articles Section =====================-->
      <div class="articles">
        <div
          class="article"
          v-for="article in Articles"
          v-bind:key="article.ID"
        >
          <div class="article_box">
            <div class="Title">
              <h2>{{ article.Title }}</h2>
            </div>
            <!-- <div class="Content">
              <div :class="`p-${article.ID}`"></div>
            </div> -->
            <div class="Date">{{ article.Date.split("T")[0] }}</div>
          </div>
          <div class="btns">
            <button v-on:click="showEditArticlePopup(article)">
              <i class="fa-regular fa-pen-to-square"></i>
            </button>
            <button v-on:click="deleteArticle(article.ID)">
              <i class="fa-solid fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
      <!--===================== Edit Article Popup Section =====================-->
      <div v-if="EditArticle">
        <div class="add_article_popup">
          <div class="dispage">
            <div class="form">
              <div class="close">
                <button v-on:click="hideEditArticlePopup()">
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </div>
              <div class="input">
                <h2>Edit Article</h2>
              </div>
              <div class="input">
                <div>
                  <input
                    type="text"
                    name="Title"
                    placeholder="Title"
                    v-model="NTitle"
                    class="Ntitle_input"
                  />
                  <br />
                  <label for="Title" class="Ntitle_input_label"></label>
                </div>
              </div>
              <div class="input">
                <div class="rich_text" id="rich_text_2">
                  <vue-editor v-model="NArticleContent" class="rs"></vue-editor>
                  <label class="Nrich_text_label"></label>
                </div>
              </div>
              <div class="input">
                <button class="add_article_btn" v-on:click="editArticle()">
                  Edit Article
                </button>
              </div>
              <div class="input">
                <label class="success_msg_2"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
import jquery from "jquery";
let $ = jquery;
export default {
  data() {
    return {
      Articles: [],
      Title: "",
      ArticleContent: "",
      AddArticle: false,
      CategoryID: this.$route.params.id,
      Article: "",
      NTitle: "",
      NArticleContent: "",
    };
  },
  components: {
    VueEditor,
  },
  async mounted() {
    this.$store.dispatch("isAgentLogIN");
    await axios
      .get(`/Agent/getArticles/${this.$route.params.id}`, {
        headers: { token: localStorage.getItem("token") },
      })
      .then(async (res) => {
        this.Articles = await res.data;
      });
    await this.ap();
  },
  methods: {
    ap() {
      for (let i = 0; i < this.Articles.length; i++) {
        $(`.p-${this.Articles[i].ID}`).append(this.Articles[i].ArticleContent);
      }
    },
    showAddArticle() {
      this.AddArticle = true;
    },
    closeAddArticle() {
      this.AddArticle = false;
    },
    async addArticle() {
      $(".title_input").click(function () {
        window.onkeydown = function () {
          $(".title_input").css("borderBottom", "3px solid #8884ff");
          $(".title_input_label").text("");
        };
      });
      if (this.Title == "" && this.ArticleContent == "") {
        $(".title_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".title_input_label").text("Please Enter a Title");
        $(".rich_text_label").text("Please Enter a Content for the Article");
        return;
      }
      if (this.Title == "") {
        $(".title_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".title_input_label").text("Please Enter a Title");
        return;
      }
      if (this.ArticleContent == "") {
        $(".rich_text_label").text("Please Enter a Content for the Article");
        return;
      }
      await axios
        .post(
          "/Agent/addArticle",
          {
            Title: this.Title,
            ArticleContent: this.ArticleContent,
            CategoryID: this.CategoryID,
          },
          { headers: { token: localStorage.getItem("token") } }
        )
        .then((res) => {
          if (res.data.msg == "Article Created") {
            location.reload();
          }
        });
    },
    showEditArticlePopup(article) {
      this.NTitle = article.Title;
      this.NArticleContent = article.ArticleContent;
      $(".add_article_popup").fadeIn(200);
      this.Article = article;
    },
    hideEditArticlePopup() {
      $(".add_article_popup").fadeOut(200);
    },
    async editArticle() {
      $(".Ntitle_input").click(function () {
        window.onkeydown = function () {
          $(".Ntitle_input").css("borderBottom", "3px solid #8884ff");
          $(".Ntitle_input_label").text("");
        };
      });
      if (this.NTitle == "" && this.NArticleContent == "") {
        $(".Ntitle_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".Ntitle_input_label").text("Please Enter a Title");
        $(".Nrich_text_label").text("Please Enter a Content for the Article");
        return;
      }
      if (this.NTitle == "") {
        $(".Ntitle_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".Ntitle_input_label").text("Please Enter a Title");
        return;
      }
      if (this.NArticleContent == "") {
        $(".Nrich_text_label").text("Please Enter a Content for the Article");
        return;
      }
      await axios
        .patch(
          `/editArticle/${this.Article.ID}`,
          {
            nTitle: this.NTitle,
            nArticleContent: this.NArticleContent,
          },
          { headers: { token: localStorage.getItem("token") } }
        )
        .then((res) => {
          console.log(res);
          if (res.data.msg == "Article Updated") {
            $(".success_msg_2").text("Article Updated");
          }
        });
    },
    async deleteArticle(ID) {
      await axios
        .delete(`/Agent/deleteArticle/${ID}`, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.msg == "Article Deleted") {
            for (let i = 0; i < this.Articles.length; i++) {
              if (this.Articles[i].ID == ID) {
                this.Articles.splice(i, 1);
              }
            }
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/******************** Articles Section ********************/
a {
  color: #1f2833;
}
.ss {
  display: flex;
  align-items: center;
  margin: 4rem;
  text-align: left;
}
.addc_Btn {
  width: 80px;
  height: 40px;
  background-color: #8884ff;
  color: #eee;
  margin-left: 1rem;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #6562d1;
  }
}
.articles {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin: 4rem;
}
.article {
  width: 300px;
  height: 150px;
  text-align: justify;
  box-shadow: 2px 2px 15px 7.5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 1rem;
  transition: all 300ms ease-in-out;
}
.article_box {
  div {
    margin: 1rem;
  }
}
.Content {
  width: 270px;
  height: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  p {
    text-align: justify;
  }
}
.btns {
  button {
    opacity: 0;
    visibility: hidden;
    width: 30px;
    height: 30px;
    border-radius: 100px;
    color: #eee;
    background-color: #8884ff;
    margin-left: 1.5rem;
    transition: all 300ms ease-in-out;
    &:hover {
      cursor: pointer;
      background-color: #eee;
      color: #8884ff;
    }
  }
}
.article:hover {
  background-color: #1f2833;
  color: #8884ff;
  transform: scale(1.2);
  .btns {
    button {
      opacity: 1;
      visibility: visible;
    }
  }
}
@media screen and (max-width: 1650px) {
  .articles {
    grid-template-columns: auto auto auto;
  }
}
@media screen and (max-width: 1426px) {
  .articles {
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 1114px) {
  .articles {
    grid-template-columns: auto;
  }
}
@media screen and (max-width: 570px) {
  .article {
    width: 200px;
  }
  .Content {
    width: 170px;
  }
}
@media screen and (max-width: 470px) {
  .articles,
  .ss {
    margin: 1rem;
  }
}
/******************** Add Article Section ********************/
.add_article {
  margin: 4rem;
  h3 {
    text-align: left;
  }
}
.input {
  padding: 2rem;
  display: flex;
  justify-content: flex-start;
}
.title_input,
.Ntitle_input {
  border: none;
  background: none;
  outline: none;
  width: 365px;
  height: 40px;
  color: #eeeeee;
  border-bottom: 3px solid #8884ff;
  padding: 10px;
  font-size: 16px;
}
.title_input {
  color: #1f2833;
}
.rs {
  color: #eeeeee;
}
.title_input_label,
.rich_text_label {
  color: rgb(250, 82, 82);
}
.rich_text {
  width: 365px;
}
#rich {
  width: 70%;
}
.add_article_btn {
  width: 365px;
  height: 40px;
  background-color: #8884ff;
  border-radius: 10px;
  color: #eeeeee;
  font-size: 14.5px;
}

@media screen and (max-width: 830px) {
  .title_input,
  #rich,
  .add_article_btn {
    width: 250px;
  }
}
@media screen and (max-width: 467px) {
  .add_article {
    margin: 1rem;
  }
}
@media screen and (max-width: 420px) {
  .title_input,
  #rich,
  .add_article_btn {
    width: 180px;
  }
}
/******************** Edit Article popup Section ********************/
.add_article_popup {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background: #12181e87;
  z-index: 3500;
  display: flex;
  flex-direction: column;
  display: none;
}
.dispage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 3000;
}
.form {
  width: 600px;
  height: auto;
  background-color: #1f2833;
  border-radius: 15px;
}
#rich_text_2 {
  width: 500px;
}
.close {
  text-align: right;
  margin: 1rem;
  button {
    color: #eeeeee;
    transition: all 300ms ease-in-out;
    i {
      font-size: 26px;
    }
    &:hover {
      cursor: pointer;
      color: #8884ff;
    }
  }
}
.input {
  padding: 1rem;
  h2 {
    color: #eeeeee;
  }
}
.category_name_input,
.new_category_name_input {
  background: none;
  border: none;
  outline: none;
  border-bottom: 3px solid #8884ff;
  width: 265px;
  height: 30px;
  color: #eeeeee;
  font-size: 15px;
}
.Nrich_text_label,
.Ntitle_input_label {
  color: rgb(250, 82, 82);
}
.add_btn_popup {
  background-color: #8884ff;
  width: 265px;
  height: 40px;
  color: #eeeeee;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #6c69c8;
  }
}
.success_msg_1,
.success_msg_2 {
  color: #eeeeee;
}
@media screen and (max-width: 470px) {
  .form {
    width: 300px;
  }
  .Ntitle_input,
  .rich_text {
    width: 250px;
  }
}
</style>
