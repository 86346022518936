<template>
  <div class="bo">
    <!--====================== Numbers ======================-->
    <div class="boxes">
      <h3>Status</h3>
      <div class="go">
        <div class="wrapper">
          <div class="container">
            <i class="fa-regular fa-envelope-open icon"></i>
            <span class="num">{{ ticketsNumber }}</span>
            <span class="text">Tickets</span>
          </div>
          <div class="container">
            <i class="fa-solid fa-location-pin icon"></i>
            <span class="num">{{ messagesNumber }}</span>
            <span class="text">Messages</span>
          </div>
          <div class="container">
            <i class="fa-regular fa-user icon"></i>
            <span class="num">{{ usersNumber }}</span>
            <span class="text">Users</span>
          </div>
          <div class="container">
            <i class="fa-regular fa-envelope icon"></i>
            <span class="num">{{ closeTicketsNumber }}</span>
            <span class="text">Closed Tickets</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      ticketsNumber: 0,
      messagesNumber: 0,
      usersNumber: 0,
      closeTicketsNumber: 0,
    };
  },
  async mounted() {
    this.$store.dispatch("isAdminLogIN");
    await axios
      .get("/Admin/getNumbers", {
        headers: { token: localStorage.getItem("token") },
      })
      .then((res) => {
        this.ticketsNumber = res.data.ticketsNumber.number;
        this.messagesNumber = res.data.messagesNumber.number;
        this.usersNumber = res.data.usersNumber.number;
        this.closeTicketsNumber = res.data.closeTicketsNumber.number;
      });
  },
};
</script>

<style lang="scss" scoped>
.bo {
  height: 100%;
}
/************************ Status ************************/
.boxes {
  text-align: left;
  margin-top: 45px;
  z-index: 1500;
  h3 {
    margin-left: 2rem;
    margin-bottom: 2rem;
    font-size: 22px;
  }
}
.wrapper {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin: 1rem;
}
.container {
  width: 300px;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em 0;
  position: relative;
  font-size: 16px;
  border-radius: 0.5em;
  margin: 1rem;
  background-color: #1f2833;
  border-bottom: 10px solid #8884ff;
}
.icon {
  color: #8884ff;
  font-size: 2.5em;
  text-align: center;
}
span.num {
  color: #ffffff;
  display: grid;
  place-items: center;
  font-weight: 600;
  font-size: 3em;
}
span.text {
  color: #e0e0e0;
  font-size: 1em;
  text-align: center;
  pad: 0.7em 0;
  font-weight: 400;
  line-height: 0;
}
@media screen and (max-width: 1561px) {
  .wrapper {
    grid-template-columns: auto auto auto;
  }
}
@media screen and (max-width: 1325px) {
  .wrapper {
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 998px) {
  .wrapper {
    grid-template-columns: auto;
  }
  .go {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 470px) {
  .container {
    width: 250px;
  }
}
@media screen and (max-width: 427px) {
  .container {
    width: 180px;
    height: 200px;
  }
}
</style>
