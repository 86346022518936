<template>
  <div>
    <div class="manage">
      <div class="s">
        <h2>Manage Agents</h2>
        <button class="add_agent_btn" v-on:click="showAddPopup()">
          Add Agent <i class="fa-solid fa-plus"></i>
        </button>
      </div>
    </div>
    <div class="manage">
      <div class="s">
        <div>
          <h2>Upgrade User</h2>
          <div class="input1">
            <div>
              <input
                type="email"
                placeholder="E-mail"
                v-model="nEmail"
                class="email-input"
                id="newAgent_email"
              />
              <br />
              <label class="email-input-label"></label>
            </div>
            <button class="add_agent_btn" v-on:click="upgradeUser()">
              Upgrade
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--================================= Display Agents =================================-->
    <div>
      <div class="title">
        <h2>Agents</h2>
      </div>
      <div class="agents">
        <div class="agent" v-for="agent in Agents" v-bind:key="agent.ID">
          <div class="icon"><i class="fa-regular fa-user"></i></div>
          <div class="info">
            <div class="name">{{ agent.Name }}</div>
            <div class="email">{{ agent.Email }}</div>
          </div>
          <div class="delete">
            <button v-on:click="removeAgent(agent)">
              <i class="fa-solid fa-trash fa-bounce"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--================================= Add agent Popup =================================-->
    <div v-if="addPopup">
      <div class="popup">
        <div class="dispage">
          <div class="form">
            <div class="close">
              <button v-on:click="closeAddPopup()">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div class="input">
              <h2>Add Agent</h2>
            </div>
            <div class="input">
              <div class="a">
                <input
                  type="text"
                  name="Name"
                  placeholder="Name"
                  v-model="Name"
                  class="text-input"
                  id="name_input"
                />
                <br />
                <label for="name_input" class="name_input_label"></label>
              </div>
            </div>
            <div class="input">
              <div class="a">
                <input
                  type="text"
                  name="Email"
                  placeholder="E-mail"
                  v-model="Email"
                  class="text-input"
                  id="email_input"
                />
                <br />
                <label for="email_input" class="email_input_label"></label>
              </div>
            </div>
            <div class="input">
              <div class="a">
                <input
                  type="password"
                  name="Password"
                  placeholder="Password"
                  v-model="Password"
                  class="text-input"
                  id="password_input"
                />
                <br />
                <label
                  for="password_input"
                  class="password_input_label"
                ></label>
              </div>
            </div>
            <div class="input">
              <button class="add_agent" v-on:click="addAgent()">
                Add Agent
              </button>
            </div>
            <div class="input">
              <label class="success_msg_1"></label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import jquery from "jquery";
let $ = jquery;
export default {
  data() {
    return {
      addPopup: false,
      Name: "",
      Email: "",
      Password: "",
      nEmail: "",
      Agents: [],
    };
  },
  async mounted() {
    this.loadAgents();
  },
  methods: {
    async loadAgents() {
      await axios
        .get("/Admin/getAgents", {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          this.Agents = res.data;
        });
    },
    showAddPopup() {
      this.addPopup = true;
      setTimeout(() => {
        $(".popup").fadeIn(200);
      }, 0);
    },
    closeAddPopup() {
      $(".popup").fadeOut(200);
      setTimeout(() => {
        this.addPopup = false;
      }, 1000);
    },
    async addAgent() {
      $("#name_input").click(function () {
        window.onkeydown = function () {
          $("#name_input").css("borderBottom", "3px solid #8884ff");
          $(".name_input_label").text("");
        };
      });

      $("#email_input").click(function () {
        window.onkeydown = function () {
          $("#email_input").css("borderBottom", "3px solid #8884ff");
          $(".email_input_label").text("");
        };
      });

      $("#password_input").click(function () {
        window.onkeydown = function () {
          $("#password_input").css("borderBottom", "3px solid #8884ff");
          $(".password_input_label").text("");
        };
      });

      if (this.Name == "" && this.Email == "" && this.Password == "") {
        $("#name_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".name_input_label").text("Please Enter a Name");

        $("#email_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".email_input_label").text("Please Enter an Email");

        $("#password_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".password_input_label").text("Please Enter a Password");
        return;
      }

      if (this.Name == "") {
        $("#name_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".name_input_label").text("Please Enter a Name");
        return;
      }
      if (this.Email == "") {
        $("#email_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".email_input_label").text("Please Enter an Email");
        return;
      }

      if (this.Password == "") {
        $("#password_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".password_input_label").text("Please Enter a Password");
        return;
      }

      await axios
        .post(
          "/Admin/addAgent",
          { Name: this.Name, Email: this.Email, Password: this.Password },
          { headers: { token: localStorage.getItem("token") } }
        )
        .then((res) => {
          if (res.data.err == "Email Already Exists") {
            $("#email_input").css("borderBottom", "3px solid rgb(250,82,82)");
            $(".email_input_label").text("Email already exists");
            return;
          }
          if (res.data.msg == "Agent Created") {
            $(".success_msg_1").text("Agent Created");
            setTimeout(() => {
              $(".success_msg_1").text("");
            }, 1500);
            return;
          }
        });
    },
    async upgradeUser() {
      $("#newAgent_email").click(function () {
        window.onkeydown = function () {
          $("#newAgent_email").css("borderBottom", "3px solid #8884ff");
          $(".email-input-label").text("");
        };
      });
      await axios
        .patch(
          "/Admin/updateUserToAgent",
          { Email: this.nEmail },
          { headers: { token: localStorage.getItem("token") } }
        )
        .then((res) => {
          if (res.data.msg == "User Updated") {
            this.loadAgents();
            this.nEmail = "";
          }
          if (res.data.err == "User Not found") {
            $("#newAgent_email").css(
              "borderBottom",
              "3px solid rgb(250,82,82)"
            );
            $(".email-input-label").text("User not Found");
          }
        });
    },
    async removeAgent(agent) {
      await axios
        .patch(
          `/Admin/removeAgent`,
          { UserID: agent.ID },
          {
            headers: { token: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          if (res.data.msg == "Agent removed") {
            for (let i = 0; i < this.Agents.length; i++) {
              if (this.Agents[i].ID == agent.ID) {
                this.Agents.splice(i, 1);
              }
            }
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.manage {
  margin: 3rem;
}
.s {
  display: flex;
  align-items: center;
  text-align: left;
}
.add_agent_btn {
  width: 90px;
  height: 40px;
  margin: 1rem;
  background-color: #8884ff;
  color: #eeeeee;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #6966c7;
  }
}
.input1 {
  display: flex;
  align-items: center;
  padding: 1.2rem;
}
.email-input {
  background: none;
  border: none;
  outline: none;
  border-bottom: 3px solid #8884ff;
  width: 300px;
  height: 30px;
  padding: 10px;
  color: #1f2833;
  font-size: 15px;
  transition: all 300ms ease-in-out;
}

/******************** Display Agents Section ********************/
.title {
  margin: 4rem;
  text-align: left;
}
.agents {
  margin: 4rem;
  display: grid;
  grid-template-columns: auto auto auto;
}
.agent {
  display: flex;
  align-items: center;
  width: 350px;
  height: 120px;
  margin: 1rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 0px 15px 10px rgb(0, 0, 0, 0.1);
  border-bottom: 4px solid #8884ff;
  transition: all 300ms ease-in-out;
}
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100%;
  i {
    color: #8884ff;
    font-size: 32px;
  }
}
.info {
  width: 150px;
  text-align: left;
  overflow-wrap: break-word;
}
.delete {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    opacity: 0;
    width: 30px;
    height: 30px;
    border-radius: 100px;
    background-color: #8884ff;
    color: #eeeeee;
    transition: all 300ms ease-in-out;
    &:hover {
      cursor: pointer;
      background-color: #eeeeee;
      color: #8884ff;
    }
  }
}
.agent:hover {
  color: #eeeeee;
  background-color: #1f2833;
  .delete {
    button {
      opacity: 1;
    }
  }
}
@media screen and (max-width: 1500px) {
  .agents {
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 1197px) {
  .agents {
    grid-template-columns: auto;
  }
}
@media screen and (max-width: 800px) {
  .email-input {
    width: 220px;
  }
}
@media screen and (max-width: 800px) {
  .agents {
    margin: 2rem;
  }
  .agent {
    width: 230px;
  }
  .icon {
    width: 60px;
  }
  .info {
    width: 110px;
  }
  .delete {
    width: 60px;
  }
}
@media screen and (max-width: 590px) {
  .input1,
  .s {
    display: block;
  }
}
@media screen and (max-width: 470px) {
  .manage {
    margin: 1rem;
  }
  .agents {
    margin: 1rem;
  }
  .input1 {
    padding: 0.5rem;
  }
}
@media screen and (max-width: 405px) {
  .agent {
    width: 180px;
  }
  .icon {
    width: 40px;
  }
  .info {
    width: 100px;
  }
  .delete {
    width: 40px;
  }
}
/******************** Add Agent popup Section ********************/
.popup {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background: #12181e87;
  z-index: 3500;
  display: flex;
  flex-direction: column;
  display: none;
}
.dispage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 3000;
}
.form {
  width: 500px;
  height: 450px;
  background-color: #1f2833;
  border-radius: 15px;
}
.close {
  text-align: right;
  margin: 1rem;
  button {
    color: #eeeeee;
    transition: all 300ms ease-in-out;
    i {
      font-size: 26px;
    }
    &:hover {
      cursor: pointer;
      color: #8884ff;
    }
  }
}
.input {
  display: flex;
  justify-content: center;
  padding: 1.2rem;
  h2 {
    color: #eeeeee;
  }
}
.a {
  text-align: left;
}
.text-input {
  background: none;
  border: none;
  outline: none;
  border-bottom: 3px solid #8884ff;
  width: 365px;
  height: 30px;
  padding: 10px;
  color: #eeeeee;
  font-size: 15px;
  transition: all 300ms ease-in-out;
}
.name_input_label,
.email_input_label,
.password_input_label {
  color: rgb(250, 82, 82);
}
.add_agent {
  background-color: #8884ff;
  width: 365px;
  height: 40px;
  color: #eeeeee;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #6c69c8;
  }
}
.success_msg_1,
.success_msg_2 {
  color: #eeeeee;
}
</style>
