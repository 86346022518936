<template>
  <div>
    <div class="page">
      <div class="Messages">
        <div class="back_link">
          <router-link to="/profile"
            ><i class="fa-solid fa-arrow-left"></i> Back to profile</router-link
          >
        </div>
        <div class="ticket_info">
          {{ Ticket.Ticket_Subject }} - Type : {{ Ticket.Type }} - Date :
          {{ date }}
        </div>
        <div class="msg">
          <div
            v-for="message in Messages"
            v-bind:key="message.ID"
            :id="`message-${message.ID}`"
            class="message"
          >
            <!-- v-if="message.UserID == AID" -->
            <div class="name" :id="`name-${message.ID}`">
              {{ Ticket.Name }}
            </div>
            <div class="content" :id="`content-${message.ID}`">
              <p>{{ message.Message }}</p>
              <div class="images" v-if="message.images.length != 0">
                <div
                  class="image"
                  v-for="image in message.images"
                  v-bind:key="image.ID"
                  v-on:click="openPhotoViewer(message.images)"
                >
                  <img :src="`/uploads/${image.Image_Name}`" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form" v-if="Ticket.Closed != 'Closed'">
        <div class="choose_file">
          <label for="choose_file">
            <input
              type="file"
              name="CategoryImage"
              ref="file"
              @change="onFileSelected"
              id="choose_file"
              multiple
            />
            <span><i class="fa-solid fa-paperclip"></i></span>
          </label>
        </div>
        <input
          type="text"
          class="message_input"
          v-model="Message"
          @keyup.enter="sendMessage()"
          placeholder="Enter a Message"
        />
        <button v-on:click="sendMessage()" class="send_msg_btn">
          <i class="fa-regular fa-paper-plane"></i>
        </button>
      </div>
      <div class="form" v-if="Ticket.Closed == 'Closed'">
        <p class="closedMsg">This Ticket is Closed</p>
      </div>
      <div class="dispage" v-if="photoViewer">
        <div class="photo_viewer">
          <div class="close_button">
            <button v-on:click="closePhotoViewer()">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div class="ma">
            <button class="prev_btn" v-on:click="prevImg()">
              <i class="fa-solid fa-chevron-left"></i>
            </button>
            <div class="photo">
              <div class="img">
                <img :src="`/uploads/${Images[currentIndex].Image_Name}`" />
              </div>
            </div>
            <button class="next_btn" v-on:click="nextImg()">
              <i class="fa-solid fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import jquery from "jquery";
let $ = jquery;
export default {
  data() {
    return {
      Messages: [],
      UID: "",
      Ticket: {},
      date: "",
      photoViewer: false,
      Images: [],
      currentIndex: 0,
      Files: [],
      Message: "",
    };
  },
  async mounted() {
    this.$store.dispatch("isLogIN");
    this.loadMessages();
  },
  methods: {
    async loadMessages() {
      await axios
        .get(`/User/getMessages/${this.$route.params.id}`, {
          headers: { token: localStorage.getItem("token") },
        })
        .then(async (res) => {
          this.Messages = await res.data.messages;
          this.UID = await res.data.uid;
          this.Ticket = await res.data.tikcetInfo;
          this.date = this.Ticket.Date.split("T")[0];
          for (let i = 0; i < this.Messages.length; i++) {
            if (this.Messages[i].UserID != this.UID) {
              $(`#name-${this.Messages[i].ID}`).hide();
              $(`#message-${this.Messages[i].ID}`).addClass("left");
              $(`#content-${this.Messages[i].ID}`).addClass("user");
            } else {
              $(`#name-${this.Messages[i].ID}`).show();
              $(`#message-${this.Messages[i].ID}`).addClass("right");
              $(`#content-${this.Messages[i].ID}`).removeClass("user");
            }
          }
        });
    },
    onFileSelected() {
      this.Files = this.$refs.file.files;
    },
    openPhotoViewer(Images) {
      this.photoViewer = true;
      this.Images = Images;
      this.currentIndex = 0;
    },
    closePhotoViewer() {
      this.photoViewer = false;
    },
    prevImg() {
      if (this.currentIndex != 0) {
        this.currentIndex -= 1;
      }
    },
    nextImg() {
      if (this.currentIndex < 2) {
        this.currentIndex += 1;
      }
    },
    async sendMessage() {
      $(".message_input").click(function () {
        window.onkeydown = function () {
          $(".message_input").css("border", "1.2px solid rgb(85,85,85)");
        };
      });

      if (this.Message == "") {
        $(".message_input").css("border", "1.2px solid rgb(250,82,82)");
        return;
      }

      const fd = new FormData();
      fd.append("Message", this.Message);
      fd.append("TicketID", this.$route.params.id);
      for (let i = 0; i < this.Files.length; i++) {
        fd.append("Images", this.Files[i]);
      }
      await axios
        .post("/User/sendMessage", fd, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.msg == "Message Sent") {
            this.loadMessages();
            this.Files = [];
            this.Message = "";
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Messages {
  padding: 4rem;
  height: 100vh;
  overflow-y: scroll;
  background-color: #1f2833;
}
.back_link {
  text-align: left;
  margin-bottom: 2rem;
  a {
    color: #eeeeee;
    font-weight: 700;
    transition: all 300ms ease-in;
    &:hover {
      color: #8884ff;
    }
  }
}
.ticket_info {
  color: #eeeeee;
  text-align: left;
}
.message {
  margin: 1rem;
  width: 350px;
  text-align: justify;
}
.left {
  position: relative;
  left: 0%;
}
.right {
  position: relative;
  right: -70%;
}
.name {
  color: #eeeeee;
}
.content {
  background-color: #8884ff;
  color: #eeeeee;
  padding: 10px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow-wrap: break-word;
}
.images {
  display: flex;
  justify-content: center;
}
.image {
  width: 80px;
  height: 70px;
  margin: 1rem;
  img {
    width: 100%;
    height: 100%;
  }
}
.image:hover {
  cursor: pointer;
}
.user {
  color: #1f2833;
  background-color: #e0e0e0;
}
.form {
  position: fixed;
  width: 100%;
  bottom: 0%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #1f2833;
}
.message_input {
  width: 950px;
  height: 50px;
  background: none;
  border: none;
  outline: none;
  color: #eeeeee;
  font-size: 16px;
  padding: 10px;
  border: 1.2px solid rgb(85, 85, 85);
  border-radius: 10px;
}
::placeholder {
  font-size: 16px;
}
.choose_file label {
  display: block;
  border: 2px dashed #8884ff;
  padding: 15px;
  margin: 10px;
  text-align: center;
  cursor: pointer;
}
.choose_file #choose_file {
  outline: none;
  opacity: 0;
  width: 0;
}
.choose_file span {
  font-size: 14px;
  color: #8884ff;
}
.choose_file label:hover span {
  text-decoration: underline;
}
.send_msg_btn {
  color: #eeeeee;
  width: 50px;
  height: 50px;
  font-size: 18px;
  background-color: #8884ff;
  border-radius: 15px;
}
.closedMsg {
  color: #eeeeee;
  font-size: 22px;
  padding: 2rem;
}
/******************** Photo Viewer Section ********************/
.photo_viewer {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  z-index: 2500;
  background-color: #575c6370;
  height: 100vh;
}
.dispage {
  display: flex;
  flex-direction: column;
  z-index: 3000;
  background-color: red;
}
.close_button {
  width: 100%;
  text-align: right;
  padding: 2rem;
  button {
    font-size: 32px;
    color: #eeeeee;
    transition: all 300ms ease-in-out;
    &:hover {
      cursor: pointer;
      color: #8884ff;
    }
  }
}
.ma {
  display: flex;
  height: 80vh;
  padding: 2rem;
  justify-content: space-between;
  align-items: center;
}
.photo {
  display: flex;
  .img {
    img {
      width: 100%;
      height: 100%;
      max-height: 70vh;
    }
  }
}
.prev_btn,
.next_btn {
  font-size: 32px;
  color: #eeeeee;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    color: #8884ff;
  }
}
@media screen and (max-width: 490px) {
  .message {
    width: 200px;
  }
}
@media screen and (max-width: 420px) {
  .message_input {
    width: 180px;
  }
  .right {
    position: relative;
    right: 0%;
  }
}
</style>
