<template>
  <div>
    <NavBar />
    <div>
      <div class="ss">
        <h2>Articles</h2>
      </div>
      <!--===================== Articles Section =====================-->
      <div class="articles">
        <div
          class="article"
          v-for="article in Articles"
          v-bind:key="article.ID"
        >
          <div class="article_box">
            <router-link :to="`/Article/${article.ID}`">
              <div class="Title">
                <h2>{{ article.Title }}</h2>
              </div>
              <div class="Date">{{ article.Date.split("T")[0] }}</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import axios from "axios";
export default {
  components: {
    NavBar,
  },
  data() {
    return {
      Articles: [],
    };
  },
  async mounted() {
    await axios
      .get(`/User/getArticles/${this.$route.params.id}`)
      .then((res) => {
        this.Articles = res.data;
      });
  },
};
</script>

<style lang="scss" scoped>
a {
  width: 100%;
  height: 100%;
  transition: all 300ms ease-in-out;
  color: #1f2833;
}
.ss {
  margin: 4rem;
  text-align: left;
}
.articles {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin: 4rem;
}
.article {
  width: 300px;
  height: 150px;
  text-align: justify;
  box-shadow: 2px 2px 15px 7.5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 1rem;
  transition: all 300ms ease-in-out;
}
.article_box {
  width: 100%;
  height: 100%;
  div {
    margin: 1rem;
  }
}
.Content {
  width: 270px;
  height: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  p {
    text-align: justify;
  }
}
.btns {
  button {
    opacity: 0;
    width: 30px;
    height: 30px;
    border-radius: 100px;
    color: #eee;
    background-color: #8884ff;
    margin-left: 1.5rem;
    transition: all 300ms ease-in-out;
    &:hover {
      cursor: pointer;
      background-color: #eee;
      color: #8884ff;
    }
  }
}
.article:hover {
  background-color: #1f2833;
  color: #8884ff;
  transform: scale(1.2);
  a {
    color: #8884ff;
  }
  .btns {
    button {
      opacity: 1;
    }
  }
}
@media screen and (max-width: 1650px) {
  .articles {
    grid-template-columns: auto auto auto;
  }
}
@media screen and (max-width: 1426px) {
  .articles {
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 1114px) {
  .articles {
    grid-template-columns: auto;
  }
}
</style>
