<template>
  <div class="a">
    <!--===================== Types Section =====================-->
    <div class="Manage_status">
      <div class="box">
        <div class="ss">
          <h2 class="title">Status</h2>
          <button class="add_btn" v-on:click="showAddStatusPopup()">
            Add <i class="fa-solid fa-plus"></i>
          </button>
        </div>
        <div class="status">
          <div class="state" v-for="state in Status" v-bind:key="state.ID">
            <div class="num">
              <div>{{ state.value }}</div>
            </div>
            <div class="name">{{ state.Status_Name }}</div>
            <div class="edit_status">
              <button
                class="edit_status_btn"
                v-on:click="showEditStatusPopup(state)"
              >
                <i class="fa-regular fa-pen-to-square"></i>
              </button>
              <button class="edit_status_btn" v-on:click="deleteStatus(state)">
                <i class="fa-solid fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--===================== Types Section =====================-->
    <div class="Manage_type">
      <div class="box">
        <div class="ss">
          <h2 class="title">Types</h2>
          <button class="add_btn" v-on:click="showAddTypePopup()">
            Add <i class="fa-solid fa-plus"></i>
          </button>
        </div>
        <div class="types">
          <div class="type" v-for="type in Types" v-bind:key="type.ID">
            <div class="type_name">{{ type.Name }}</div>
            <div class="btns">
              <button class="edit" v-on:click="showEditTypePopup(type)">
                <i class="fa-regular fa-pen-to-square"></i>
              </button>
              <button class="delete" v-on:click="deleteType(type)">
                <i class="fa-solid fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--===================== Manage Company Section =====================-->
    <div class="manage_logo_and_name">
      <div class="box">
        <div class="ss">
          <h2 class="title">Manage Website</h2>
        </div>
        <div class="Change_logo">
          <div class="logo">
            <h4>Update Logo :</h4>
            <div class="choose_file">
              <label for="choose_file">
                <input
                  type="file"
                  name="CategoryImage"
                  ref="file"
                  @change="onFileSelected"
                  id="choose_file"
                  multiple
                />
                <span><i class="fa-solid fa-paperclip"></i></span>
              </label>
            </div>
          </div>
        </div>
        <div class="website_name">
          <h4>Website Name :</h4>
          <input
            type="text"
            placeholder="Website Name"
            v-model="websiteName"
            class="website_name_input"
          />
        </div>
        <div class="updateWebsite">
          <button class="update_web_btn" v-on:click="update()">Update</button>
        </div>
        <div>
          <label class="success_msg_5"></label>
        </div>
      </div>
    </div>
    <!--===================== Status Popups Section =====================-->
    <div v-if="addStatusPopup">
      <div class="add_status_popup" id="addStatusPopup">
        <div class="dispage">
          <div class="form">
            <div class="close">
              <button v-on:click="hideAddStatusPoput()">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div class="input">
              <h2>Add Status</h2>
            </div>
            <div class="input">
              <input
                type="text"
                placeholder="Name"
                v-model="status_name"
                id="name_input"
                class="status_name_input"
              />
              <br />
              <label for="name_input" class="status_name_input_label"></label>
            </div>
            <div class="input">
              <button class="add_btn_popup" v-on:click="addStatus()">
                Add Status
              </button>
            </div>
            <div class="input">
              <label class="success_msg_1"></label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="editStatusPopup">
      <div class="add_status_popup" id="editStatusPopup">
        <div class="dispage">
          <div class="form">
            <div class="close">
              <button v-on:click="hideEditStatusPopup()">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div class="input">
              <h2>Edit Status</h2>
            </div>
            <div class="input">
              <input
                type="text"
                placeholder="New name"
                v-model="new_status_name"
                id="name_input"
                class="new_status_name_input"
              />
              <br />
              <label
                for="name_input"
                class="new_status_name_input_label"
              ></label>
            </div>
            <div class="input">
              <button class="add_btn_popup" v-on:click="editStatus()">
                Edit Status
              </button>
            </div>
            <div class="input">
              <label class="success_msg_2"></label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--===================== Types Popups Section =====================-->
    <div v-if="addTypePopup">
      <div class="add_type_popup" id="addTypePopup">
        <div class="dispage">
          <div class="form">
            <div class="close">
              <button v-on:click="hideAddTypePopup()">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div class="input">
              <h2>Add Type</h2>
            </div>
            <div class="input">
              <input
                type="text"
                placeholder="Name"
                v-model="type_name"
                id="name_input"
                class="type_name_input"
              />
              <br />
              <label for="name_input" class="type_name_input_label"></label>
            </div>
            <div class="input">
              <button class="add_btn_popup" v-on:click="addType()">
                Add Type
              </button>
            </div>
            <div class="input">
              <label class="success_msg_3"></label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="editTypePopup">
      <div class="add_status_popup" id="editTypePopup">
        <div class="dispage">
          <div class="form">
            <div class="close">
              <button v-on:click="hideEditTypePopup()">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div class="input">
              <h2>Edit Type</h2>
            </div>
            <div class="input">
              <input
                type="text"
                placeholder="New name"
                v-model="new_type_name"
                id="name_input"
                class="new_type_name_input"
              />
              <br />
              <label for="name_input" class="new_type_name_input_label"></label>
            </div>
            <div class="input">
              <button class="add_btn_popup" v-on:click="editType()">
                Edit Type
              </button>
            </div>
            <div class="input">
              <label class="success_msg_4"></label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import jquery from "jquery";
let $ = jquery;
export default {
  data() {
    return {
      Types: [],
      Status: [],
      addStatusPopup: false,
      editStatusPopup: false,
      addTypePopup: false,
      editTypePopup: false,
      status_name: "",
      StatusID: "",
      new_status_name: "",
      type_name: "",
      TypeID: "",
      new_type_name: "",
      File: "",
      websiteName: "",
    };
  },
  async mounted() {
    this.$store.dispatch("isAdminLogIN");
    this.loadStatus();
    this.loadTypes();
  },
  methods: {
    async loadStatus() {
      await axios
        .get("/Admin/getSidebar", {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          this.Status = res.data;
        });
    },
    async loadTypes() {
      await axios
        .get("/Admin/getTypes", {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          this.Types = res.data;
        });
    },
    onFileSelected() {
      this.File = this.$refs.file.files[0];
    },
    // Status Section
    showAddStatusPopup() {
      this.addStatusPopup = true;
      setTimeout(() => {
        $("#addStatusPopup").fadeIn(200);
      }, 0);
    },
    hideAddStatusPoput() {
      $("#addStatusPopup").fadeOut(200);
      setTimeout(() => {
        this.addStatusPopup = false;
      }, 1000);
    },
    showEditStatusPopup(state) {
      this.editStatusPopup = true;
      this.StatusID = state.ID;
      this.new_status_name = state.Status_Name;
      setTimeout(() => {
        $("#editStatusPopup").fadeIn(200);
      }, 0);
    },
    hideEditStatusPopup() {
      this.editStatusPopup = false;
      this.new_status_name = "";
      $("#editStatusPopup").fadeOut(200);
      setTimeout(() => {
        this.editStatusPopup = false;
      }, 1000);
    },
    async addStatus() {
      $(".status_name_input").click(function () {
        window.onkeydown = function () {
          $(".status_name_input").css("borderBottom", "3px solid #8884ff");
          $(".status_name_input_label").text("");
        };
      });
      if (this.status_name == "") {
        $(".status_name_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".status_name_input_label").text("Please Enter a Stauts Name");
        return;
      }
      await axios
        .post(
          "/Admin/addStatus",
          { Name: this.status_name },
          { headers: { token: localStorage.getItem("token") } }
        )
        .then((res) => {
          console.log(res);
          if (res.data.msg == "Status Created") {
            $(".success_msg_1").text("Status Created");
            this.loadStatus();
          }
        });
    },
    async editStatus() {
      $(".new_status_name_input").click(function () {
        window.onkeydown = function () {
          $(".new_status_name_input").css("borderBottom", "3px solid #8884ff");
          $(".new_status_name_input_label").text("");
        };
      });
      if (this.new_status_name == "") {
        $(".new_status_name_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".new_status_name_input_label").text("Please Enter a Stauts Name");
        return;
      }
      await axios
        .patch(
          `/Admin/editStatus/${this.StatusID}`,
          { Newname: this.new_status_name },
          {
            headers: { token: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.msg == "Status Updated") {
            $(".success_msg_2").text("Status Updated");
            this.loadStatus();
          }
        });
    },
    async deleteStatus(state) {
      await axios
        .delete(`/Admin/deleteStatus/${state.ID}`, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.msg == "Status Deleted") {
            for (let i = 0; i < this.Status.length; i++) {
              if (this.Status[i].ID == state.ID) {
                this.Status.splice(i, 1);
              }
            }
          }
        });
    },
    // Types Section
    showAddTypePopup() {
      this.addTypePopup = true;
      setTimeout(() => {
        $("#addTypePopup").fadeIn(200);
      }, 0);
    },
    hideAddTypePopup() {
      $("#addTypePopup").fadeOut(200);
      setTimeout(() => {
        this.addTypePopup = false;
      }, 1000);
    },
    showEditTypePopup(type) {
      this.TypeID = type.ID;
      this.new_type_name = type.Name;
      this.editTypePopup = true;
      setTimeout(() => {
        $("#editTypePopup").fadeIn(200);
      }, 0);
    },
    hideEditTypePopup() {
      this.new_type_name = "";
      $("#editTypePopup").fadeOut(200);
      setTimeout(() => {
        this.editTypePopup = false;
      }, 1000);
    },
    async addType() {
      $(".type_name_input").click(function () {
        window.onkeydown = function () {
          $(".type_name_input").css("borderBottom", "3px solid #8884ff");
          $(".type_name_input_label").text("");
        };
      });
      if (this.type_name == "") {
        $(".type_name_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".type_name_input_label").text("Please Enter a Type Name");
        return;
      }
      await axios
        .post(
          "/Admin/addType",
          { Name: this.type_name },
          { headers: { token: localStorage.getItem("token") } }
        )
        .then((res) => {
          if (res.data.msg == "Type Created") {
            $(".success_msg_3").text("Type Created");
            this.loadTypes();
          }
        });
    },
    async editType() {
      $(".new_type_name_input").click(function () {
        window.onkeydown = function () {
          $(".new_type_name_input").css("borderBottom", "3px solid #8884ff");
          $(".new_type_name_input_label").text("");
        };
      });
      if (this.new_type_name == "") {
        $(".new_type_name_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".new_type_name_input_label").text("Please Enter a Type Name");
        return;
      }
      await axios
        .patch(
          `/Admin/editType/${this.TypeID}`,
          { Newname: this.new_type_name },
          {
            headers: { token: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          if (res.data.msg == "Type Updated") {
            $(".success_msg_4").text("Type Updated");
            this.loadTypes();
          }
        });
    },
    async deleteType(Type) {
      await axios
        .delete(`/Admin/deleteType/${Type.ID}`, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.msg == "Type Deleted") {
            for (let i = 0; i < this.Types.length; i++) {
              if (this.Types[i].ID == Type.ID) {
                this.Types.splice(i, 1);
              }
            }
          }
        });
    },
    // website
    async update() {
      const fd = new FormData();
      fd.append("websiteName", this.websiteName);
      fd.append("Logo", this.File);
      await axios
        .patch("/Admin/updateWebsite", fd, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.msg == "Website Updated") {
            $(".success_msg_5").text("Name and Logo Updated");
            setTimeout(() => {
              location.reload();
            }, 1500);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/******************** Global classes Section ********************/
.a {
  display: flex;
  flex-direction: column;
}
.ss {
  display: flex;
  text-align: left;
  align-items: center;
}
/******************** Status Section ********************/
.Manage_status {
  display: flex;
  justify-content: center;
}
.box {
  margin-top: 2rem;
}
.status {
  margin: 4rem;
  display: grid;
  grid-template-columns: auto auto auto auto;
}
.state {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px;
  width: 300px;
  height: 80px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom: 5px solid #8884ff;
}
.num {
  display: flex;
  justify-content: center;
  width: 100px;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    color: #eeeeee;
    background-color: #1f2833;
    border-radius: 100px;
  }
}
.name {
  width: 100px;
}
.edit_status {
  width: 100px;
}
.edit_status_btn {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  color: #eeeeee;
  border-radius: 20px;
  background-color: #8884ff;
  opacity: 0;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #6c69c8;
  }
}
.state:hover {
  .edit_status_btn {
    opacity: 1;
  }
}
@media screen and (max-width: 1640px) {
  .status {
    grid-template-columns: auto auto auto;
  }
}
@media screen and (max-width: 1412px) {
  .status {
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 1069px) {
  .status {
    grid-template-columns: auto;
  }
}

/******************** Types Section ********************/
.Manage_type {
  display: flex;
  justify-content: center;
}
.types {
  margin: 4rem;
  display: grid;
  grid-template-columns: auto auto auto auto;
}
.type {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  margin-top: 35px;
  width: 300px;
  height: 80px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom: 5px solid #8884ff;
}
.type_name {
  width: 200px;
}
@media screen and (max-width: 1640px) {
  .types {
    grid-template-columns: auto auto auto;
  }
}
@media screen and (max-width: 1412px) {
  .types {
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 1069px) {
  .types {
    grid-template-columns: auto;
  }
}
@media screen and (max-width: 695px) {
  .status,
  .types {
    margin: 1rem;
  }
  .state,
  .type {
    width: 200px;
  }
}
.add_btn {
  margin-left: 1rem;
  width: 70px;
  height: 40px;
  background-color: #8884ff;
  color: #eeeeee;
  border-radius: 10px;
  box-shadow: 2px 2px 15px 5px rgba(0, 0, 0, 0.1);
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #6c69c8;
  }
}
.btns {
  opacity: 0;
  transition: all 300ms ease;
}
.edit,
.delete {
  width: 30px;
  height: 30px;
  background-color: #8884ff;
  color: #eeeeee;
  border-radius: 100px;
  margin-left: 1rem;
  transition: all 300ms ease-in-out;
}
.edit:hover {
  cursor: pointer;
  background-color: #6c69c8;
}
.delete:hover {
  cursor: pointer;
  background-color: #6c69c8;
}
.type:hover {
  .btns {
    opacity: 1;
  }
}
/******************** Popup Section ********************/
.add_status_popup,
.add_type_popup {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background: #12181e87;
  z-index: 3500;
  display: flex;
  flex-direction: column;
  display: none;
}
.dispage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 3000;
}
.form {
  width: 400px;
  height: 350px;
  background-color: #1f2833;
  border-radius: 30px;
}
.close {
  text-align: right;
  margin: 1rem;
  button {
    color: #eeeeee;
    transition: all 300ms ease-in-out;
    i {
      font-size: 26px;
    }
    &:hover {
      cursor: pointer;
      color: #8884ff;
    }
  }
}
.input {
  padding: 1rem;
  h2 {
    color: #eeeeee;
  }
}
input {
  background: none;
  border: none;
  outline: none;
  border-bottom: 3px solid #8884ff;
  width: 265px;
  height: 30px;
  color: #eeeeee;
  font-size: 15px;
}
.status_name_input_label,
.type_name_input_label,
.new_status_name_input_label,
.new_type_name_input_label {
  color: rgb(250, 82, 82);
}
.add_btn_popup {
  background-color: #8884ff;
  width: 265px;
  height: 40px;
  color: #eeeeee;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #6c69c8;
  }
}
.success_msg_1,
.success_msg_2,
.success_msg_3,
.success_msg_4 {
  color: #eeeeee;
}
.success_msg_5 {
  color: #1f2833;
}
/******************** Manage website Section ********************/
.manage_logo_and_name {
  width: 450px;
  margin: 4rem;
}
.Change_logo {
  display: flex;
  align-items: center;
}
.logo {
  display: flex;
  align-items: center;
  h4 {
    margin-right: 2rem;
  }
}
.choose_file label {
  display: block;
  border: 2px dashed #8884ff;
  width: 265px;
  padding: 15px;
  margin: 10px;
  text-align: center;
  cursor: pointer;
}
.choose_file #choose_file {
  outline: none;
  opacity: 0;
  width: 0;
}
.choose_file span {
  font-size: 14px;
  color: #8884ff;
}
.choose_file label:hover span {
  text-decoration: underline;
}
.website_name {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  h4 {
    margin-right: 2rem;
  }
}
.website_name_input {
  color: #1f2833;
}
.updateWebsite {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}
.update_web_btn {
  width: 265px;
  height: 40px;
  background-color: #8884ff;
  color: #eeeeee;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #6c69c8;
  }
}
@media screen and (max-width: 899px) {
  .manage_logo_and_name {
    width: 350px;
  }
  .choose_file label {
    width: 180px;
  }
  .update_web_btn,
  .website_name_input {
    width: 180px;
  }
}
@media screen and (max-width: 597px) {
  .manage_logo_and_name {
    width: 250px;
  }
}
@media screen and (max-width: 490px) {
  .manage_logo_and_name {
    margin: 1rem;
    width: 200px;
  }
  .choose_file label {
    width: 120px;
  }
  .update_web_btn,
  .website_name_input {
    width: 120px;
  }
}
</style>
