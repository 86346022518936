import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import HomeView from "../views/HomeView.vue";

import LoginView from "../views/LoginView.vue";
import SignupView from "../views/SignupView.vue";

import TicketView from "../views/TicketView.vue";
import profileView from "../views/ProfileView.vue";
import UserTicket from "../views/UserTicket.vue";

import AdminDashboard from "../views/Admin/AdminDashboard.vue";
import ManageTickets from "../views/Admin/ManageTickets.vue";
import ReplyView from "../views/Admin/ReplyView.vue";
import Dashboard from "../views/Admin/DashboardView.vue";
import knowledgeBase from "../views/Admin/KnowledgeBase.vue";
import CategoryView from "../views/Admin/CategoryView.vue";
import StatusView from "../views/Admin/StatusView.vue";
import ManageAgents from "../views/Admin/ManageAgents.vue";
import ArticlesView from "../views/ArticlesView.vue";
import ArticleView from "../views/ArticleView.vue";

import AgentDashboard from "../views/Agent/AgentDashboard.vue";
import AgentTickets from "../views/Agent/AgentTickets.vue";
import AgentReply from "../views/Agent/AgentReply.vue";
import AgentKnowledgeBase from "../views/Agent/AgentKnowledgeBase.vue";
import AgentCategory from "../views/Agent/AgentCategory.vue";

import forgetPassword from "../views/forgetPassword.vue";
import setNewPassword from "../views/setNewPassword.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/Login",
    name: "Login",
    component: LoginView,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/Signup",
    name: "Signup",
    component: SignupView,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/Admin",
    name: "Admin",
    component: AdminDashboard,
    meta: {
      requiresAdmin: true,
    },
    children: [
      {
        path: "Status",
        name: "Status",
        component: StatusView,
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: "Dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: "Tickets",
        name: "Tickets",
        component: ManageTickets,
        meta: {
          requiresAdmin: true,
        },
      },

      {
        path: "knowledgeBase",
        name: "knowledgeBase",
        component: knowledgeBase,
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: "Category/:id",
        name: "Category",
        component: CategoryView,
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: "ManageAgents",
        name: "ManageAgents",
        component: ManageAgents,
        meta: {
          requiresAdmin: true,
        },
      },
    ],
  },
  {
    path: "/Reply/:id",
    name: "Reply",
    component: ReplyView,
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "/Agent",
    name: "Agent",
    component: AgentDashboard,
    meta: {
      requiresAgent: true,
    },
    children: [
      {
        path: "AgentTickets",
        name: "AgentTickets",
        component: AgentTickets,
        meta: {
          requiresAgent: true,
        },
      },

      {
        path: "AgentKnowledgeBase",
        name: "AgentKnowledgeBase",
        component: AgentKnowledgeBase,
        meta: {
          requiresAgent: true,
        },
      },
      {
        path: "AgentCategory/:id",
        name: "AgentCategory",
        component: AgentCategory,
        meta: {
          requiresAgent: true,
        },
      },
    ],
  },
  {
    path: "/AgentReply/:id",
    name: "AgentReply",
    component: AgentReply,
    meta: {
      requiresAgent: true,
    },
  },
  {
    path: "/Ticket",
    name: "Ticket",
    component: TicketView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: profileView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/Ticket/:id",
    name: "UserTicket",
    component: UserTicket,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/Articles/:id",
    name: "Articles",
    component: ArticlesView,
  },
  {
    path: "/Article/:id",
    name: "Article",
    component: ArticleView,
  },
  {
    path: "/forgetPassword",
    name: "forgetPassword",
    component: forgetPassword,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/setNewPassword/:id",
    name: "setNewPassword",
    component: setNewPassword,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const isLoggedIn = store.getters.isLoggedIn;
const isAgent = store.getters.isAgentLoggedIn;
const isAdmin = store.getters.isAdminLoggedIn;

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (isLoggedIn || isAdmin || isAgent) {
      next("/");
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (isAdmin) {
      next();
    } else if (isLoggedIn) {
      next("/profile");
    } else if (isAgent) {
      next("/Agent");
    } else {
      next("/");
    }
  } else if (to.matched.some((record) => record.meta.requiresAgent)) {
    if (isAgent) {
      next();
    } else if (isAdmin) {
      next("/Admin");
    } else if (isLoggedIn) {
      next("/profile");
    } else {
      next("/");
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isAdmin) {
      next("/Admin");
    } else if (isAgent || isLoggedIn) {
      next();
    } else {
      next("/Login");
    }
  } else {
    next();
  }
});

export default router;
